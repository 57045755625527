import { ErrorsMessages } from "@/utils/errors";
import { getZoomCode } from "@/utils/helper";
import {
  EMAIL_VALIDATORS,
  NAME_VALIDATORS,
  PASSWORD_VALIDATORS,
  VLIDATORS,
} from "@/utils/validators";
import Vue from "vue";

export default Vue.extend({
  name: "Register",
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        captcha: "",
      },
      isAgreed: false,
      passwordValidators: PASSWORD_VALIDATORS,
      emailValidators: EMAIL_VALIDATORS,
      nameValidators: NAME_VALIDATORS,
      rules: VLIDATORS,
      showPass: false,
      errorsMessages: ErrorsMessages,
    };
  },
  methods: {
    async submit() {
      this.clearError(null);
      const isValid = (
        this.$refs.form as Vue & { validate: () => boolean }
      ).validate();
      if (isValid) {
        this.$store.dispatch("profile/SET_REGISTER_LOADING_ACTION", true);
        await this.$recaptchaLoaded();
        this.form.captcha = await this.$recaptcha("login");
        const code = getZoomCode();
        const userData = { ...this.form, ...(code && { code }) };
        this.$store.dispatch("profile/REGISTER_USER", userData);
      }
    },
    clearError(code: number | null) {
      if (this.errorCode) this.$store.dispatch("CLEAR_API_ERROR_ACTION", code);
    },
  },
  computed: {
    loading(): boolean {
      return this.$store.state.profile.isRegisterLoading;
    },
    errorCode(): number | null {
      return this.$store.state.errorAPI;
    },
  },
  beforeDestroy() {
    this.clearError(null);
  },
});
