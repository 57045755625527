import Vue from "vue";

interface ISnackbar {
  text: string;
  timeout: number;
  deleteIDs: number[] | null;
  duplicatedId: string | null;
  deleteTaskId: number | null;
}

export default Vue.extend({
  name: "Snackbar",
  data() {
    return {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      storeSubscription: () => {},
      snackbar: false,
      snackbarData: <ISnackbar>{
        text: "",
        timeout: 3000,
        duplicatedId: null,
        deleteTaskId: null,
      },
      queue: <ISnackbar[]>[],
      counter: 0,

      snackbarTimeout: 0,
      interval: 0,
      timerValue: 0,
    };
  },
  created() {
    this.storeSubscription = this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "analyzes/SET_DUPLICATED_TASK":
          this.queue.push({
            text: "Analysis duplicated.",
            timeout: 3000,
            deleteIDs: null,
            duplicatedId: state.analyzes.duplicatedTask,
            deleteTaskId: null,
          });
          if (this.queue.length === 1) this.startQueue();
          break;
        case "analyzes/ADD_TASK_TO_DELETE":
          this.queue.push({
            text: "Analysis will be deleted",
            timeout: 5000,
            deleteIDs: null,
            deleteTaskId: mutation.payload,
            duplicatedId: null,
          });
          if (this.queue.length === 1) this.startQueue();
          break;
        case "analyzes/ADD_MULTIPLY_DELETE":
          this.queue.push({
            text: `${mutation.payload.length} analyzes will be deleted`,
            timeout: 5000,
            deleteIDs: mutation.payload,
            deleteTaskId: null,
            duplicatedId: null,
          });
          if (this.queue.length === 1) this.startQueue();
          break;
        case "analyzes/DELETE_TASK":
          this.queue.push({
            text: "Analysis deleted.",
            timeout: 3000,
            deleteIDs: null,
            deleteTaskId: null,
            duplicatedId: null,
          });
          if (this.queue.length === 1) this.startQueue();
          break;
        case "analyzes/BULK_DELETE_SUCCESS":
          this.queue.push({
            text: "Analyzes deleted.",
            timeout: 3000,
            deleteIDs: null,
            deleteTaskId: null,
            duplicatedId: null,
          });
          if (this.queue.length === 1) this.startQueue();
          break;
        default:
          break;
      }
    });
  },
  methods: {
    async startQueue() {
      this.counter = 0;
      do {
        this.snackbar = true;
        this.snackbarData = { ...this.queue[this.counter] };
        if (
          this.queue[this.counter].deleteTaskId ||
          this.queue[this.counter].deleteIDs
        ) {
          this.timerValue = 0;
          this.interval = setInterval(() => {
            if (this.timerValue === 100) {
              return clearInterval(this.interval);
            }
            this.timerValue += 20;
          }, 1000);
        }

        await new Promise((resolve) => {
          this.snackbarTimeout = setTimeout(() => {
            const _delId = this.queue[this.counter].deleteTaskId;
            if (_delId && this.$tasksToDelete.includes(_delId)) {
              this.$store.dispatch(
                "analyzes/DELETE_TASK_ACTION",
                this.queue[this.counter].deleteTaskId
              );
            }
            const _multiplyDelete = this.queue[this.counter].deleteIDs;
            if (_multiplyDelete && _multiplyDelete.length) {
              this.$store.dispatch(
                "analyzes/MULTIPLY_DELETE_TASK_ACTION",
                this.queue[this.counter].deleteIDs
              );
            }
            resolve("foo");
          }, this.queue[this.counter].timeout - 50);
        });
        if (!this.snackbar) break;
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve("foo");
          }, 400);
        });
        this.snackbar = false;
        this.counter++;
      } while (this.counter < this.queue.length);
      this.queue = [];
      this.counter = 0;
    },
    openTask() {
      if (this.snackbarData.duplicatedId) {
        this.$router.push({
          name: "AnalysisMain",
          params: { id: this.snackbarData.duplicatedId.toString() },
        });
      }
      this.closeSnackbar();
    },
    cancelDelete() {
      this.$store.dispatch(
        "analyzes/CANCEL_DELETE_ACTION",
        this.snackbarData.deleteTaskId
      );
      this.closeSnackbar(undefined, true);
    },
    cancelDeleteBulk() {
      this.$store.dispatch("analyzes/CANCEL_BULK_DELETE_ACTION");
      this.closeSnackbar(undefined, true);
    },
    closeSnackbar(event?: Event, cancelDelete = false) {
      if (this.snackbarData.deleteTaskId && !cancelDelete) {
        this.$store.dispatch(
          "analyzes/DELETE_TASK_ACTION",
          this.snackbarData.deleteTaskId
        );
      }
      if (this.snackbarData.deleteIDs && !cancelDelete) {
        this.$store.dispatch(
          "analyzes/MULTIPLY_DELETE_TASK_ACTION",
          this.snackbarData.deleteIDs
        );
      }
      this.snackbar = false;
      if (this.snackbarTimeout) {
        clearInterval(this.snackbarTimeout);
        this.queue.splice(0, this.counter + 1);

        if (this.queue.length)
          setTimeout(() => {
            this.startQueue();
          }, 300);
      }
    },
  },
  computed: {
    $tasksToDelete(): number[] {
      return this.$store.state.analyzes.tasksToDelete;
    },
  },
  beforeDestroy() {
    this.storeSubscription();
  },
});
