import Vue from "vue";
import Footer from "@/components/Footer/index.vue";
import { getCookie } from "@/utils/helper";

export default Vue.extend({
  name: "WebexConfirmation",
  components: { Footer },
  data() {
    return {
      confirmSuccess: <boolean | null>null,
      error: <string | null>null,
      loading: true,
    };
  },
  computed: {
    isWebexConnected(): boolean | null {
      return this.$store.state.profile.user.webex_authorized;
    },
  },
  methods: {
    goToNewAnalysis() {
      if (!this.$store.state.isSocketConnected) {
        this.$store.dispatch("CONNECT_TO_WS");
      }

      this.$store.dispatch("profile/GET_USER_SETTINGS");
      this.$router.replace({ name: "NewAnalysis" });
    },
    goToProfile() {
      if (!this.$store.state.isSocketConnected) {
        this.$store.dispatch("CONNECT_TO_WS");
      }

      this.$router.replace({ name: "AccountSettings" });
    },
    confirmWebexAccount() {
      const accessToken = getCookie("access-token");

      if (!accessToken) {
        this.$router.replace({ name: "Login" });
        return;
      }

      const { state, code } = this.$route.query;
      const isValidState = state === process.env.VUE_APP_WEBEX_STATE;

      if (!code || !isValidState) {
        this.error = "Confirmation link is invalid. Code is missing";
        this.loading = false;
      } else {
        this.$store
          .dispatch("webex/CONFIRM_WEBEX_ACCOUNT", code)
          .then((isSuccessfully) => {
            if (isSuccessfully) {
              this.confirmSuccess = true;
            }
            this.loading = false;
          })
          .catch(() => {
            this.confirmSuccess = false;
            this.loading = false;
          });
      }
    },
  },
  watch: {
    isWebexConnected: {
      handler(newValue) {
        if (newValue) {
          this.loading = false;
        } else {
          this.confirmWebexAccount();
        }
      },
    },
  },
});
