import Vue from "vue";
import { Analysis } from "@/store/analyzes/analyzes-types";
import RenameSpeakersModal from "@/components/modals/RenameSpeakersModal/index.vue";

export default Vue.extend({
  name: "SpeakersList",
  components: { RenameSpeakersModal },
  props: ["showLoader", "showAbortAlert"],
  data() {
    return {
      renameDialog: false,
    };
  },
  computed: {
    $storeAnalysis(): Analysis {
      return this.$store.state.analyzes.currentAnalyze;
    },
    coefficient(): number {
      return this.$store.getters["analyzes/GET_COEFFICIENT"];
    },
    hasSelect(): boolean {
      if (this.$storeAnalysis.timelineData) {
        return this.$storeAnalysis.timelineData.main.some(
          (el) => el.visible == true
        );
      } else {
        return false;
      }
    },
  },
  methods: {
    setSelectedToAll(bool: boolean) {
      if (this.$storeAnalysis.timelineData)
        this.$storeAnalysis.timelineData.main.forEach((el) => {
          el.visible = bool;
        });
    },
    openRenameSpeakersDialog() {
      this.renameDialog = true;
    },
    getSpeakerName(speaker: number): string {
      return this.$storeAnalysis.speakers_names[speaker] || `Person ${speaker}`;
    },
  },
});
