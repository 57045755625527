import Vue from "vue";

export default Vue.extend({
  name: "RootHeader",
  data() {
    return {
      form: {
        captcha: "",
      },
    };
  },
  methods: {
    register_guest() {
      if (this.$store.state.profile.user.id) {
        this.$router.push("/new-analysis");
      } else {
        this.$store.dispatch("profile/SET_REGISTER_LOADING_ACTION", true);
        this.$store.dispatch("profile/REGISTER_GUEST");
      }
    },
    clearError(code: number | null) {
      if (this.errorCode) this.$store.dispatch("CLEAR_API_ERROR_ACTION", code);
    },
  },
  computed: {
    loading(): boolean {
      return this.$store.state.profile.isRegisterLoading;
    },
    errorCode(): number | null {
      return this.$store.state.errorAPI;
    },
  },
  beforeDestroy() {
    this.clearError(null);
  },
});
