import Vue from "vue";
import LinkAccount from "../LinkAccount/index.vue";
import RecordsTable from "./RecordsTable/index.vue";
import { IWebexRecord } from "@/store/webex/webex-types";

export default Vue.extend({
  name: "WebexInput",
  props: ["uploadedRecord", "isUploadFiles", "uploadWebexFile"],
  components: {
    LinkAccount,
    RecordsTable,
  },
  computed: {
    webexAuthorized(): boolean {
      return this.$store.state.profile.user.webex_authorized;
    },
    webexRecords(): IWebexRecord[] {
      return this.$store.state.webex.meetings;
    },
    webexLoading(): boolean {
      return this.$store.state.webex.isMeetingsLoading;
    },
  },
});
