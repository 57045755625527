import { Module } from "vuex";
import axios from "axios";
import router from "@/router";
import { client } from "@/store";
import { deleteCookie, removeZoomCode, sizeFormat } from "@/utils/helper";
import {
  IUserCancelChangeEmailRequest,
  IUserGetSettingsRequest,
  IUserTariffSettingsRequest,
} from "@/types/ws-request-types";
import {
  IUserSettingsDataRes,
  IUserTariffSettingsDataRes,
  IZoomAuthDataRes,
} from "@/types/ws-response-types";
import { ISubscribeInfo, ProfileState } from "./profile-types";
import { RootState } from "../store-types";
import { Tariff } from "@/types/shared-types";
import { Location } from "vue-router";

const api = process.env.VUE_APP_API_XHR_PROTOCOL + process.env.VUE_APP_API_HOST;
const initialState: ProfileState = {
  user: {
    id: null,
    email: "",
    email_confirmed: false,
    new_email: "",
    change_password_time: "",
    zoom_authorized: false,
    zombty_authorized: false,
    webex_authorized: null,
    tariff: "freemium",
    paymentManageLink: "",
    cancel_at_period_end: false,
    tariff_period_end: 0,
    tariffSettings: {
      analyzes_total: 0,
      analyzes_count: 0,
      storage_total: 0,
      storage_fill: 0,
    },
    subscribeList: [],
    is_guest: false,
  },
  isRegisterLoading: false,
  isLoginLoading: false,
  isUpdateTariff: false,
  isConfirmLoading: false,
  isSendingSupport: false,
  isZombtyLinkLoading: false,
  errorSubscribeList: false,
  decodedEmail: "", // не пустой если токен некорректный
  prices: {
    plans: {
      standart: "10",
      standart_plus: "19",
      premium: "99",
    },
    sub_plans: {
      mb: "1",
      gb: "1",
      tb: "1",
    },
  },
};
export const profile: Module<ProfileState, RootState> = {
  namespaced: true,
  state: { ...initialState },
  getters: {
    GET_ANALYZES_AVAILABLE(state: ProfileState) {
      return (
        state.user.tariffSettings.analyzes_total -
        state.user.tariffSettings.analyzes_count
      );
    },
    GET_STORAGE_AVAILABLE(state: ProfileState) {
      return (
        state.user.tariffSettings.storage_total -
        state.user.tariffSettings.storage_fill
      );
    },
    GET_INFO_ANALYZES_AVAILABLE(state: ProfileState) {
      const totalAnalyzesAvailable = state.user.email_confirmed
        ? state.user.tariffSettings.analyzes_total
        : state.user.is_guest
        ? state.user.tariffSettings.analyzes_total
        : 1;

      return `${
        totalAnalyzesAvailable - state.user.tariffSettings.analyzes_count
      } / ${totalAnalyzesAvailable}`;
    },
    GET_INFO_STORAGE_FILL(state: ProfileState) {
      return `${sizeFormat(
        state.user.tariffSettings.storage_fill
      )} / ${sizeFormat(state.user.tariffSettings.storage_total)}`;
    },
    GET_INFO_STORAGE_AVAILABLE(state: ProfileState) {
      return `${sizeFormat(
        state.user.tariffSettings.storage_total -
          state.user.tariffSettings.storage_fill
      )} / ${sizeFormat(state.user.tariffSettings.storage_total)}`;
    },
    GET_STORAGE_VOLUME_PERCENT(state: ProfileState) {
      return +(
        (state.user.tariffSettings.storage_fill /
          state.user.tariffSettings.storage_total) *
        100
      ).toFixed();
    },
    GET_ISGUEST_VALUE(state: ProfileState) {
      return state.user.is_guest && state.user.id;
    },
  },
  mutations: {
    SET_USER_SETTINGS(state: ProfileState, user: IUserSettingsDataRes) {
      state.user = { ...state.user, ...user };
      if (!user.new_email) state.user.new_email = "";
    },
    SET_USER_TARIFF_SETTINGS(
      state: ProfileState,
      settings: IUserTariffSettingsDataRes
    ) {
      state.user.tariffSettings = {
        ...state.user.tariffSettings,
        ...settings,
      };
    },
    CLEAR_USER_DATA(state: ProfileState) {
      state.user = { ...initialState.user };
    },
    SET_DECODED_EMAIL(state: ProfileState, email: string) {
      state.decodedEmail = email;
    },
    CLEAR_DECODED_EMAIL(state: ProfileState) {
      state.decodedEmail = "";
    },

    SET_REGISTER_LOADING(state: ProfileState, payload: boolean) {
      state.isRegisterLoading = payload;
    },
    SET_LOGIN_LOADING(state: ProfileState, payload: boolean) {
      state.isLoginLoading = payload;
    },
    SET_CONFIRM_LOADING(state: ProfileState, payload: boolean) {
      state.isConfirmLoading = payload;
    },
    SET_SUPPORT_SENDING_LOADING(state: ProfileState, payload: boolean) {
      state.isSendingSupport = payload;
    },
    SET_ZOOM_AUTHORIZATION(state: ProfileState, isAuth: boolean) {
      state.user.zoom_authorized = isAuth;
    },
    SET_TARIFF_LOADING(state: ProfileState, payload: boolean) {
      state.isUpdateTariff = payload;
    },
    SET_SUBSCRIBE_LIST(state: ProfileState, payload: ISubscribeInfo[]) {
      state.user.subscribeList = payload;
    },
    SET_ERROR_SUBSCRIBE_LIST(state: ProfileState) {
      state.errorSubscribeList = true;
    },
    CLEAR_ERROR_SUBSCRIBE_LIST(state: ProfileState) {
      state.errorSubscribeList = false;
    },
    SET_PAYMENT_MANAGE_LINK(state: ProfileState, payload: string) {
      state.user.paymentManageLink = payload;
    },
    SET_LINK_ZOMBTY_LOADING(state, payload: boolean) {
      state.isZombtyLinkLoading = payload;
    },
    SET_PRICES(state, payload) {
      state.prices = payload;
    },
  },
  actions: {
    async GET_PRICES({ commit, dispatch }) {
      try {
        const { data } = await axios.get(`${api}/api/payment/pricing`);
        if (!data.response) {
          throw new Error("Something went wrong. Try to again later");
        }
        commit("SET_PRICES", data.response);
      } catch (e) {
        dispatch("API_ERROR_HANDLER", e, { root: true });
      }
    },
    SET_REGISTER_LOADING_ACTION({ commit }, payload: boolean) {
      commit("SET_REGISTER_LOADING", payload);
    },
    SET_LOGIN_LOADING_ACTION({ commit }, payload: boolean) {
      commit("SET_LOGIN_LOADING", payload);
    },
    GET_USER_SETTINGS() {
      const request: IUserGetSettingsRequest = {
        path: "user.get_settings",
      };
      client.sendWSRequest(request);
    },
    SET_USER_SETTINGS_ACTION({ commit, dispatch }, user: IUserSettingsDataRes) {
      commit("SET_USER_SETTINGS", user);
      if (user.tariff !== "freemium") dispatch("GET_PAYMENT_MANAGE_LINK");
    },
    GET_USER_TARIFF_SETTINGS() {
      const request: IUserTariffSettingsRequest = {
        path: "user.tariff_settings",
      };
      client.sendWSRequest(request);
    },
    SET_USER_TARIFF_SETTINGS_ACTION(
      { commit, dispatch, state },
      settings: IUserTariffSettingsDataRes
    ) {
      commit("SET_USER_TARIFF_SETTINGS", settings);
      state.user.tariff !== "freemium" && dispatch("GET_SUBSCRIBE_LIST");
    },
    SET_ZOOM_AUTHORIZATION_ACTION({ commit }, data: IZoomAuthDataRes) {
      commit("SET_ZOOM_AUTHORIZATION", data.authorized);
    },
    async ADD_STORAGE_SPACE(
      { dispatch },
      data: { sub_plan: string; quantity: number }
    ) {
      try {
        const res = await axios.post(`${api}/api/payment/addMemory`, data);
        const redirectUrl = res?.data?.response.redirect_url;

        if (redirectUrl) location.assign(redirectUrl);
      } catch (err) {
        router.push({
          name: "PaymentCanceled",
          params: { message: "No extra space added." },
        });
      }
    },
    REGISTER_USER({ dispatch }, form) {
      axios
        .post(`${api}/auth/signUp`, form)
        .then((res) => {
          dispatch("SET_REGISTER_LOADING_ACTION", false);
          if (res?.data?.response) {
            removeZoomCode();
            dispatch("CONNECT_TO_WS", null, { root: true });
            localStorage.removeItem("isGuest");

            const redirectPath = localStorage.getItem("redirectPath");

            if (redirectPath) {
              const { name }: Location = JSON.parse(redirectPath);

              if (name) {
                router.replace({ name });
              }
            } else {
              router.replace({ name: "NewAnalysis" });
            }

            dispatch("SET_SUCCESS_ALERT", 1, { root: true });
          }
        })
        .catch((err) => {
          dispatch("SET_REGISTER_LOADING_ACTION", false);
          dispatch("API_ERROR_HANDLER", err, { root: true });
        });
    },
    async REGISTER_GUEST({ dispatch }) {
      await axios
        .post(`${api}/auth/guest/signUp`)
        .then((res) => {
          dispatch("SET_REGISTER_LOADING_ACTION", false);
          if (res?.data?.response) {
            removeZoomCode();
            dispatch("CONNECT_TO_WS", null, { root: true });
            localStorage.setItem("isGuest", "true");
            router.push({ name: "NewAnalysis" });
          }
        })
        .catch((err) => {
          dispatch("SET_REGISTER_LOADING_ACTION", false);
          dispatch("API_ERROR_HANDLER", err, { root: true });
        });
      return dispatch;
    },
    DO_LOGIN({ dispatch }) {
      localStorage.removeItem("isGuest");
      removeZoomCode();
      dispatch("CONNECT_TO_WS", null, { root: true });
      dispatch("SET_LOGIN_LOADING_ACTION", false);
      const redirectPath = localStorage.getItem("redirectPath");

      if (localStorage.getItem("tariff")) {
        router.replace({ name: "Tariffication" });
      } else if (redirectPath) {
        const { name }: Location = JSON.parse(redirectPath);

        if (name) {
          router.replace({ name });
        }
      } else {
        router.replace({ name: "AnalyzesTable" });
      }
    },
    DO_LOGIN_CATCH({ dispatch }, err: unknown) {
      dispatch("SET_LOGIN_LOADING_ACTION", false);
      dispatch("API_ERROR_HANDLER", err, { root: true });
      router.replace({ name: "Login" }).catch(() => {
        /**/
      });
    },
    async LOGIN_USER({ dispatch }, form) {
      try {
        const { data } = await axios.post(`${api}/auth/signIn`, form);
        if (!data.response) {
          throw new Error("Something went wrong. Try to again later");
        }
        dispatch("DO_LOGIN");
      } catch (e) {
        dispatch("DO_LOGIN_CATCH", e);
      }
    },
    async LOGIN_THROUGH_ZOMBTY({ dispatch }, state: string) {
      try {
        if (!state) {
          throw new Error("No state string");
        }
        dispatch("SET_LOGIN_LOADING_ACTION", true);
        const { data: { response } = {} } = await axios.post(
          `${api}/auth/zombtyOAuthConfirmed`,
          { state }
        );
        if (!response.access_token || !response.refresh_token) {
          throw new Error("No tokens get");
        }
        dispatch("DO_LOGIN");
      } catch (e) {
        dispatch("DO_LOGIN_CATCH", e);
      } finally {
        localStorage.removeItem("zombty-state");
      }
    },
    LOGOUT_USER({ dispatch, commit }) {
      deleteCookie("access-token");
      deleteCookie("refresh-token");
      dispatch("DISCONNECT_WS", null, { root: true });
      dispatch("analyzes/CLEAR_ANALYSIS_STATE_ACTION", null, {
        root: true,
      });
      commit("CLEAR_USER_DATA");
    },

    async CHANGE_EMAIL({ dispatch }, form): Promise<boolean | void> {
      try {
        const res = await axios.post(`${api}/api/user/changeEmail`, form);
        if (res?.data?.response) {
          dispatch("GET_USER_SETTINGS");
          return res.data.response;
        } else return false;
      } catch (err) {
        dispatch("API_ERROR_HANDLER", err, { root: true });
      }
    },
    async REPLY_CHANGE_EMAIL({ dispatch }): Promise<boolean | void> {
      try {
        const res = await axios.get(
          `${api}/api/user/replySendTokenConfirmChangeEmail`
        );
        if (res?.data?.response) return res.data.response;
        else return false;
      } catch (err) {
        dispatch("API_ERROR_HANDLER", err, { root: true });
      }
    },
    async REPLY_CONFIRM_EMAIL({ dispatch }): Promise<boolean | void> {
      try {
        const res = await axios.get(
          `${api}/api/user/replySendTokenConfirmSignUp`
        );
        if (res?.data?.response) return res.data.response;
        else return false;
      } catch (err) {
        dispatch("API_ERROR_HANDLER", err, { root: true });
      }
    },
    CONFIRM_EMAIL({ commit }, token: string) {
      commit("SET_CONFIRM_LOADING", true);
      return axios
        .post(`${api}/auth/signUpConfirm`, { token })
        .then((res) => {
          commit("SET_CONFIRM_LOADING", false);
          if (res?.data?.response) return res?.data?.response;
          else throw new Error("error");
        })
        .catch((err) => {
          commit("SET_CONFIRM_LOADING", false);
          if (err?.response?.data?.error) {
            throw new Error(err.response.data.error.message);
          } else {
            throw new Error(err);
          }
        });
    },
    CONFIRM_NEW_EMAIL({ commit }, token: string) {
      commit("SET_CONFIRM_LOADING", true);
      return axios
        .post(`${api}/auth/changeEmailConfirm`, { token })
        .then((res) => {
          commit("SET_CONFIRM_LOADING", false);
          if (res?.data?.response) return res?.data?.response;
          else throw new Error("error");
        })
        .catch((err) => {
          commit("SET_CONFIRM_LOADING", false);
          if (err?.response?.data?.error) {
            throw new Error(err.response.data.error.message);
          } else {
            throw new Error(err);
          }
        });
    },
    CONFIRM_ZOOM_CODE({ commit }, code: string) {
      commit("SET_CONFIRM_LOADING", true);
      return new Promise<void>((resolve, reject) => {
        setTimeout(() => {
          commit("SET_CONFIRM_LOADING", false);
          if (code) {
            removeZoomCode();
            resolve();
          }
          reject();
        }, 1500);
      });
    },
    async LINK_ZOMBTY_ACCOUNT({ commit, dispatch }, state: string) {
      try {
        commit("SET_LINK_ZOMBTY_LOADING", true);
        const {
          data: { response },
        } = await axios.post(`${api}/api/zombty/linkZombtyAccount`, {
          state,
        });
        if (!response.linked) {
          throw new Error("Error while linking Zombty account");
        }
        dispatch("GET_USER_SETTINGS");
      } catch (err) {
        dispatch("API_ERROR_HANDLER", err, { root: true });
      } finally {
        commit("SET_LINK_ZOMBTY_LOADING", false);
      }
    },
    async UNLINK_ZOMBTY_ACCOUNT({ dispatch, state }) {
      try {
        if (!state.user.zombty_authorized) {
          throw new Error("No Zombty account linked yet");
        }
        const { data } = await axios.get(`${api}/api/zombty/deauthorization`);
        if (data.error) {
          throw data.error;
        }
        dispatch("GET_USER_SETTINGS");
      } catch (err) {
        dispatch("API_ERROR_HANDLER", err, { root: true });
      }
    },
    async UNLINK_WEBEX_ACCOUNT({ dispatch }) {
      try {
        const { data } = await axios.post(`${api}/api/webex/deauthorization`);
        if (data.error) {
          throw data.error;
        }
        dispatch("GET_USER_SETTINGS");
      } catch (err) {
        dispatch("API_ERROR_HANDLER", err, { root: true });
      }
    },
    CANCEL_CHANGE_EMAIL() {
      const request: IUserCancelChangeEmailRequest = {
        path: "user.cancel_change_email",
      };
      client.sendWSRequest(request);
    },
    CANCEL_CHANGE_EMAIL_SUCCESS({ dispatch }) {
      dispatch("GET_USER_SETTINGS");
      dispatch("SET_SUCCESS_ALERT", 3, { root: true });
    },

    async FORGOT_PASSWORD({ dispatch }, form): Promise<boolean | void> {
      try {
        const res = await axios.post(`${api}/auth/recoveryPassword`, form);
        if (res?.data?.response) return res.data.response;
        else return false;
      } catch (err) {
        dispatch("API_ERROR_HANDLER", err, { root: true });
      }
    },
    async CHANGE_PASSWORD({ dispatch }, form): Promise<boolean | void> {
      try {
        const res = await axios.post(`${api}/api/user/changePassword`, form);
        if (res?.data?.response) {
          dispatch("GET_USER_SETTINGS");
          return res.data.response;
        } else return false;
      } catch (err) {
        dispatch("API_ERROR_HANDLER", err, { root: true });
      }
    },

    SET_DECODED_EMAIL_ACTION({ commit }, email: string) {
      commit("SET_DECODED_EMAIL", email);
    },
    CLEAR_DECODED_EMAIL_ACTION({ commit }) {
      commit("CLEAR_DECODED_EMAIL");
    },
    async RECOVERY_PASSWORD_CONFIRM(
      { dispatch },
      form
    ): Promise<boolean | void> {
      try {
        const res = await axios.post(
          `${api}/auth/recoveryPasswordConfirm`,
          form
        );
        if (res?.data?.response) {
          dispatch("SET_SUCCESS_ALERT", 2, { root: true });
          return res.data.response;
        } else return false;
      } catch (err) {
        dispatch("API_ERROR_HANDLER", err, { root: true });
      }
    },

    async SUBSCRIBE_TARIFF({ dispatch }, tariff): Promise<boolean | void> {
      try {
        const res = await axios.post(
          `${api}/api/payment/subscribe`,
          {
            plan: tariff,
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        if (res?.data?.response?.redirect_url)
          window.location.assign(res?.data?.response?.redirect_url);
      } catch (err) {
        dispatch("API_ERROR_HANDLER", err, { root: true });
      }
    },

    async CHANGE_TARIFF({ dispatch }, tariff: Tariff) {
      try {
        const res = await axios.post(
          `${api}/api/payment/change`,
          {
            plan: tariff,
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        if (res?.data?.response?.redirect_url)
          window.location.assign(res?.data?.response?.redirect_url);
      } catch (err) {
        dispatch("SET_TARIFF_LOADING_ACTION", false);
        router.push({ name: "PaymentCanceled" });
      }
    },

    async UNSUBSCRIBE_TARIFF({ dispatch }) {
      try {
        const res = await axios.get(`${api}/api/payment/cancel`);
        if (res?.data?.response) {
          dispatch("GET_USER_SETTINGS");
          router.replace({
            name: "Tariffication",
            hash: "#plansScrollAnchor",
          });
        }
      } catch (err) {
        router.push({ name: "PaymentCanceled" });
      }
    },

    SET_TARIFF_LOADING_ACTION({ commit }, loading: boolean) {
      commit("SET_TARIFF_LOADING", loading);
    },

    async DELETE_ACCOUNT({ dispatch }, form): Promise<boolean | void> {
      try {
        const res = await axios.post(`${api}/api/user/deleteAccount`, form);
        if (res?.data?.response) return res.data.response;
        else return false;
      } catch (err) {
        dispatch("API_ERROR_HANDLER", err, { root: true });
      }
    },

    async FIREWALL_MESSAGE({ dispatch }, form): Promise<boolean | void> {
      try {
        const res = await axios.post(`${api}/api/farewellMessage`, form);
        if (res?.data?.response) {
          dispatch("SET_SUCCESS_ALERT", 7, { root: true });
          return res.data.response;
        } else return false;
      } catch (err) {
        dispatch("API_ERROR_HANDLER", err, { root: true });
      }
    },

    async SEND_SUPPORT_MESSAGE({ commit, dispatch }, form) {
      commit("SET_SUPPORT_SENDING_LOADING", true);
      try {
        const res = await axios.post(`${api}/api/user/supportMessage`, form);
        if (res?.data?.response) {
          dispatch("SET_SUCCESS_ALERT", 7, { root: true });
          return res.data.response;
        } else return false;
      } catch (err) {
        dispatch("API_ERROR_HANDLER", err, { root: true });
      } finally {
        commit("SET_SUPPORT_SENDING_LOADING", false);
      }
    },

    GET_SUBSCRIBE_LIST({ commit }) {
      commit("CLEAR_ERROR_SUBSCRIBE_LIST");
      axios
        .get(`${api}/api/payment/subscribeList`)
        .then((res) => {
          if (res?.data?.response) {
            commit("SET_SUBSCRIBE_LIST", res?.data?.response);
          } else commit("SET_ERROR_SUBSCRIBE_LIST");
        })
        .catch(() => commit("SET_ERROR_SUBSCRIBE_LIST"));
    },

    async GET_PAYMENT_MANAGE_LINK({ commit }) {
      const {
        data: {
          response: { link },
        },
      } = await axios.get(`${api}/api/payment/createPortalSession`);

      if (link) commit("SET_PAYMENT_MANAGE_LINK", link);
    },
  },
};
