import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import AnalyzesTable from "@/components/AnalyzesTable/index.vue";
import NewAnalysis from "@/components/NewAnalysis/index.vue";
import Analysis from "@/components/Analysis/index.vue";
import AccountSettings from "@/components/profile/AccountSettings/index.vue";
import CancelingSubscriptions from "@/components/profile/CancelingSubscriptions/index.vue";
import Auth from "@/components/auth/index.vue";
import Login from "@/components/auth/Login/index.vue";
import Register from "@/components/auth/Register/index.vue";
import Forgot from "@/components/auth/ForgotPassword/index.vue";
import Recovery from "@/components/auth/PasswordRecovery/index.vue";
import AccountDeleted from "@/components/auth/AccountDeleted/index.vue";
import AccountConfirmation from "@/components/auth/AccountConfirmation/index.vue";
import ZoomConfirmation from "@/components/auth/ZoomConfirmation/index.vue";
import WebexConfirmation from "@/components/auth/WebexConfirmation/index.vue";
import ZombtyCallback from "@/components/auth/ZombtyCallback/index.vue";
import { getCookie } from "@/utils/helper";
import AnalysisMain from "@/components/Analysis/AnalysisMain/index.vue";
import AnalysisSpeakersStatistics from "@/components/Analysis/AnalysisSpeakersStatistics/index.vue";
import Tariffication from "@/components/Tariffication/index.vue";
import Support from "@/components/profile/Support/index.vue";
import PaymentResult from "@/components/PaymentResult/index.vue";
import PaymentCanceled from "@/components/PaymentResult/PaymentCanceled/index.vue";
import PaymentProcessing from "@/components/PaymentResult/PaymentProcessing/index.vue";
import Landing from "@/components/Landing/index.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Landing",
    component: Landing,
  },
  {
    path: "/tariffication",
    name: "Tariffication",
    component: Tariffication,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/dashboard",
    name: "AnalyzesTable",
    component: AnalyzesTable,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/new-analysis",
    name: "NewAnalysis",
    component: NewAnalysis,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/analysis/:id",
    name: "Analysis",
    component: Analysis,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: "",
        name: "AnalysisMain",
        component: AnalysisMain,
      },
      {
        path: "details",
        name: "AnalysisSpeakersStatistics",
        component: AnalysisSpeakersStatistics,
      },
    ],
  },
  {
    path: "/profile",
    name: "AccountSettings",
    component: AccountSettings,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/canceling-subscriptions",
    name: "CancelingSubscriptions",
    component: CancelingSubscriptions,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/support",
    name: "Support",
    component: Support,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/account-confirmation",
    name: "AccountConfirmation",
    component: AccountConfirmation,
  },
  {
    path: "/email-confirmation",
    name: "EmailConfirmation",
    component: AccountConfirmation,
  },
  {
    path: "/webex-confirmation",
    name: "WebexConfirmation",
    component: WebexConfirmation,
  },
  {
    path: "/zoom-confirmation",
    name: "ZoomConfirmation",
    component: ZoomConfirmation,
  },
  {
    path: "/zombty-callback",
    name: "ZombtyLoginCallback",
    component: ZombtyCallback,
  },
  {
    path: "/",
    component: Auth,
    children: [
      {
        path: "login",
        name: "Login",
        component: Login,
      },
      {
        path: "register",
        name: "Register",
        component: Register,
      },
      {
        path: "forgot",
        name: "Forgot",
        component: Forgot,
      },
      {
        path: "recovery",
        name: "Recovery",
        component: Recovery,
      },
      {
        path: "account-deleted",
        name: "AccountDeleted",
        component: AccountDeleted,
      },
    ],
  },
  {
    path: "/paying-result",
    name: "PaymentResult",
    component: PaymentResult,
    props: true,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/paying-canceled",
    name: "PaymentCanceled",
    component: PaymentCanceled,
    props: true,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/payment-processing",
    name: "PaymentProcessing",
    component: PaymentProcessing,
    meta: {
      authRequired: true,
    },
  },

  {
    path: "/*",
    redirect: "/login",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
    if (savedPosition) {
      return savedPosition;
    }
  },
});

router.beforeEach((to, from, next) => {
  const token = getCookie("access-token");
  const refresh = getCookie("refresh-token");
  const isAuthRq = to.matched.some((record) => record.meta.authRequired);
  const exception = (to.name &&
    [
      "AccountConfirmation",
      "ZoomConfirmation",
      "Landing",
      "ZombtyLoginCallback",
      "WebexConfirmation",
    ].includes(to.name)) as boolean;
  if (token && !isAuthRq && !exception && refresh) next({ path: "/dashboard" });
  else if (isAuthRq) {
    if (token) next();
    else next({ path: "/login" });
  } else next();
});

export default router;
