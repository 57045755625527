import Vue from "vue";
import jwt_decode from "jwt-decode";
import { ErrorsMessages } from "@/utils/errors";
import { IJWT } from "@/types/shared-types";
import { PASSWORD_VALIDATORS, VLIDATORS } from "@/utils/validators";

export default Vue.extend({
  name: "Register",
  data() {
    return {
      form: {
        password: "",
        token: "",
      },
      decodedEmail: "",
      rules: VLIDATORS,
      passwordValidators: PASSWORD_VALIDATORS,
      errorsMessages: ErrorsMessages,
      showPass: false,
      loading: false,
    };
  },
  methods: {
    async submit() {
      const isValid = (
        this.$refs.form as Vue & { validate: () => boolean }
      ).validate();
      if (isValid) {
        this.loading = true;
        const res = await this.$store.dispatch(
          "profile/RECOVERY_PASSWORD_CONFIRM",
          this.form
        );
        this.loading = false;
        if (res) this.$router.replace({ name: "Login" });
        else console.log("Failed");
      }
    },
    clearError(code: number | number[] | null) {
      if (this.errorCode) this.$store.dispatch("CLEAR_API_ERROR_ACTION", code);
    },
  },
  computed: {
    errorCode(): number | null {
      return this.$store.state.errorAPI;
    },
  },
  created() {
    const token = this.$route.query.token as string;
    if (this.$route.query.token) {
      let decoded: IJWT;
      try {
        decoded = jwt_decode(token) as IJWT;
        if (decoded?.email && /.+@.+\..+/.test(decoded.email)) {
          if (decoded.exp && decoded.exp < Date.now() * 1000) {
            this.decodedEmail = decoded.email;
            this.form.token = token;
          } else {
            this.$store.dispatch(
              "profile/SET_DECODED_EMAIL_ACTION",
              decoded.email
            );
            this.$router.replace({ name: "Forgot" });
          }
        } else {
          this.$store.dispatch("profile/SET_DECODED_EMAIL_ACTION", "Invalid");
          this.$router.replace({ name: "Forgot" });
        }
      } catch (e) {
        this.$router.replace({ name: "Forgot" });
      }
    } else {
      this.$router.replace({ name: "Forgot" });
    }
  },
  beforeDestroy() {
    this.clearError(null);
  },
});
