import Vue from "vue";
import { Location } from "vue-router";

export default Vue.extend({
  name: "WebexApp",
  computed: {
    isAuth(): boolean {
      return this.$store.state.profile.user.id;
    },
  },
  methods: {
    toAccountSettings() {
      let location: Location = { name: "AccountSettings", hash: "#webex" };

      if (!this.isAuth) {
        const locationString = JSON.stringify(location);
        localStorage.setItem("redirectPath", locationString);
        location = { name: "Login" };
      }

      this.$router.push(location);
    },
  },
});
