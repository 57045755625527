




import Vue from "vue";
export default Vue.extend({
  name: "ZombtyCallback",
  created() {
    const state = this.$route.query.state as string;
    if (state) {
      localStorage.setItem("zombty-state", state);
      if (!window.opener) {
        return this.$store.dispatch("profile/LOGIN_THROUGH_ZOMBTY", state);
      }
    }
    setTimeout(() => {
      window.close();
      return this.$router.replace({ name: "Login" });
    }, 500);
  },
});
