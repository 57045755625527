var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"relative","background-color":"white","border-top":"1px solid var(--time-line-grid)","border-end-start-radius":"4px","border-end-end-radius":"4px"}},[_c('div',{staticClass:"slider-container"},[_c('button',[_c('v-icon',{attrs:{"size":"13","color":"var(--slider-background)"},on:{"click":function($event){return _vm.setScale(-10)}}},[_vm._v(" mdi-minus-circle-outline ")])],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.scale),expression:"scale"}],staticClass:"slider",attrs:{"type":"range","min":_vm.minScale,"max":_vm.maxScale},domProps:{"value":(_vm.scale)},on:{"__r":function($event){_vm.scale=$event.target.value}}}),_c('button',[_c('v-icon',{attrs:{"size":"13","color":"var(--slider-background)"},on:{"click":function($event){return _vm.setScale(10)}}},[_vm._v(" mdi-plus-circle-outline ")])],1)]),_c('div',{staticClass:"d-flex"},[_c('div',{staticStyle:{"font-size":"10px","line-height":"14px","min-width":"70px","border-top":"1px solid var(--time-line-grid)","margin-top":"40px","margin-right":"-8px"}},_vm._l((_vm.result.main),function(ref,index){
var speaker = ref.speaker;
return _c('div',{key:("person-" + index),staticStyle:{"height":"14px","padding-left":"8px","border-bottom":"1px solid var(--time-line-grid)","letter-spacing":"0.5px","color":"var(--secondary-text)"}},[_c('div',[_vm._v(_vm._s(_vm.getSpeakerName(speaker)))])])}),0),_c('div',{staticClass:"timeline",staticStyle:{"overflow-x":"scroll","overflow-y":"hidden","width":"100%","padding-left":"8px"}},[_c('div',{staticStyle:{"position":"relative","border-top":"1px solid var(--time-line-grid)","margin-top":"40px"},style:(("width: " + _vm.scale + "%"))},[_c('v-slider',{staticClass:"player-slider",attrs:{"dense":"","hide-details":"","min":0,"max":_vm.audioLength,"value":_vm.currentTime,"step":"0.1","thumb-label":"always"},on:{"mouseup":function($event){return _vm.$emit('tm', false)},"change":function($event){return _vm.playSelected($event)},"mousedown":function($event){return _vm.$emit('tm', true)}},scopedSlots:_vm._u([{key:"thumb-label",fn:function(){return [_c('div',{staticClass:"current-time",style:(("height: " + (_vm.result.main.length * 14 + 6) + "px"))},[_c('div')])]},proxy:true}])}),_vm._m(0),_vm._l((_vm.timeGrid),function(line,idx){return _c('div',{key:("line-" + idx),staticClass:"time-grid",style:(("left: " + (_vm.calculateStartSpeech(line * 30)) + "%"))},[_c('div',{staticClass:"time-grid-value"},[_vm._v(_vm._s(_vm.timeFormat(line, 30))+"s")])])}),(_vm.result.interruption.length)?_c('div',_vm._l((_vm.result.interruption),function(interruption,idx){return _c('div',{key:("interruption-" + idx)},[(interruption.speaker)?_c('div',{staticClass:"interruption",style:(("left: " + (_vm.calculateStartSpeech(interruption.begin)) + "%;\n                height: calc(" + (_vm.findSpeakerPosition(interruption.speaker) * 14) + "px + 15px);"))},[_c('div',{staticClass:"interrupt",style:(("top: " + (_vm.findSpeakerPosition(interruption.speaker) * 14 + 2) + "px;"))}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"interruption-value"},'div',attrs,false),on),[_vm._v(" ! ")])]}}],null,true)},[_c('span',{staticStyle:{"color":"white"}},[_c('b',[_vm._v("Interruption at "+_vm._s(_vm.timeFormat(interruption.begin, 1)))]),_c('br'),_vm._v(" Length "+_vm._s(_vm.calculateSpeechLengthTime( interruption.begin, interruption.end ))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.getSpeakerName(interruption.speaker))+" was interrupted ")])])],1):_vm._e()])}),0):_vm._e(),_vm._l((_vm.result.main),function(ref,index){
                var speech = ref.speech;
                var speaker = ref.speaker;
return _c('div',{key:index,staticClass:"speech-line"},_vm._l((speech),function(ref,idx){
                var words = ref.words;
return _c('div',{key:idx,staticStyle:{"position":"absolute","top":"0"},style:(("\n        width: " + (_vm.calculateSpeechLength(
          words[0].begin,
          words[words.length - 1].end
        )) + "%; left: " + (_vm.calculateStartSpeech(words[0].begin)) + "%"))},[_c('div',{staticClass:"d-flex",staticStyle:{"width":"100%","height":"13px","cursor":"pointer"},style:(("background-color: " + (_vm.contrastColors[index]))),on:{"click":function($event){return _vm.trigger(("speech-" + speaker + "-" + idx))}}},[_c('v-menu',{staticStyle:{"z-index":"20"},attrs:{"top":"","absolute":"","offset-y":"","min-width":"200px","max-width":"200px","nudge-top":"5","nudge-left":"100"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({ref:("speech-" + speaker + "-" + idx),refInFor:true,staticStyle:{"height":"100%","width":"100%","outline":"none"}},'button',attrs,false),on))]}}],null,true)},[_c('v-list',{attrs:{"color":"rgba(97, 97, 97, 0.9)"}},[_c('div',{staticClass:"tooltip-container"},[_c('p',{staticClass:"mb-0"},[_c('b',[_vm._v(_vm._s(_vm.getSpeakerName(speaker))+" at "+_vm._s(_vm.calculateSpeechStartTime(words[0].begin)))])]),_c('p',{staticClass:"mb-0"},[_vm._v(" Length "+_vm._s(_vm.calculateSpeechLengthTime( words[0].begin, words[words.length - 1].end ))+" ")]),_c('v-btn',{staticClass:"mt-3 tooltip-btn",attrs:{"elevation":"0","small":"","block":""},on:{"click":function($event){return _vm.playSelected(words[0].begin, true)}}},[_c('v-icon',{attrs:{"size":"16px","left":"","color":"var(--secondary-text)"}},[_vm._v("mdi-play-circle")]),_vm._v(" Play from selected ")],1)],1)])],1)],1)])}),0)})],2)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"time-grid"},[_c('div',{staticClass:"time-grid-value first"},[_vm._v("0:0")])])}]

export { render, staticRenderFns }