import Vue from "vue";

export default Vue.extend({
  name: "LinearProgress",
  props: {
    value: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: "#1976D2",
    },
    background: {
      type: String,
      default: "#e3eef9",
    },
    height: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {};
  },
});
