import { ErrorsMessages } from "@/utils/errors";
import { PASSWORD_VALIDATORS, VLIDATORS } from "@/utils/validators";
import Vue from "vue";

export default Vue.extend({
  name: "RenameModal",
  props: ["dialog", "form", "sendSuccess"],
  data() {
    return {
      confirmSuccess: <boolean | null>null,
      loading: false,

      rules: VLIDATORS,
      passwordValidators: PASSWORD_VALIDATORS,
      showPass: false,
      showNewPass: false,
      errorsMessages: ErrorsMessages,
    };
  },
  methods: {
    clearError(code: number | number[] | null) {
      if (this.errorCode) this.$store.dispatch("CLEAR_API_ERROR_ACTION", code);
    },
    async changePassword() {
      const isValid = (
        this.$refs.form as Vue & { validate: () => boolean }
      ).validate();
      if (isValid) {
        this.loading = true;
        const res = await this.$store.dispatch(
          "profile/CHANGE_PASSWORD",
          this.form
        );
        this.loading = false;
        if (res) {
          this.$store.dispatch("SET_SUCCESS_ALERT", 5);
          this.$emit("close");
        } else console.log("Failed");
      }
    },
  },
  computed: {
    errorCode(): number | null {
      return this.$store.state.errorAPI;
    },
  },
  beforeDestroy() {
    this.clearError(null);
  },
});
