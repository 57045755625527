import Vue from "vue";
import jwt_decode from "jwt-decode";
import Footer from "@/components/Footer/index.vue";
import { ErrorsMessages } from "@/utils/errors";
import { SuccessMessages } from "@/utils/success-messages";
import { IJWT } from "@/types/shared-types";

export default Vue.extend({
  name: "Auth",
  components: { Footer },
  data() {
    return {
      errorsMessages: ErrorsMessages,
      successMessages: SuccessMessages,

      confirmSuccess: <boolean | null>null,
      tokenError: "",
      replySignUpDisabled: false,
      timer: 0,
      timerInterval: 0,
    };
  },
  computed: {
    errorCode(): number | null {
      return this.$store.state.errorAPI;
    },
    successCode(): number | null {
      return this.$store.state.successCode;
    },
    loadingConfirm() {
      return this.$store.state.profile.isConfirmLoading;
    },
    formatTimer(): string {
      const min = Math.floor(this.timer / 60);
      const sec = this.timer - min * 60;
      return `${min}:${sec < 10 ? `0${sec}` : `${sec}`}`;
    },
  },
  methods: {
    goToProfile() {
      if (!this.$store.state.isSocketConnected)
        this.$store.dispatch("CONNECT_TO_WS");
      this.$router.push({ name: "AccountSettings" });
    },
    clearError(code: number | null) {
      if (this.errorCode) this.$store.dispatch("CLEAR_API_ERROR_ACTION", code);
    },
    async submit() {
      this.replySignUpDisabled = true;
      const res = await this.$store.dispatch("profile/REPLY_CONFIRM_EMAIL");
      if (res) {
        this.$store.dispatch("SET_SUCCESS_ALERT", 4);
        this.timer = 120;
        this.timerInterval = setInterval(() => {
          this.timer--;
          if (this.timer === 0) {
            clearInterval(this.timerInterval);
            return;
          }
        }, 1000);
        setTimeout(() => {
          this.replySignUpDisabled = false;
        }, 120000);
      } else {
        this.replySignUpDisabled = false;
      }
    },
    confirmEmail() {
      this.confirmSuccess = false;
      this.$store
        .dispatch("profile/CONFIRM_EMAIL", this.$route.query.token)
        .then((res) => {
          if (res) {
            this.confirmSuccess = true;
            setTimeout(() => {
              if (!this.$store.state.isSocketConnected)
                this.$store.dispatch("CONNECT_TO_WS");
              this.$router.push({ name: "AnalyzesTable" });
            }, 3500);
          } else this.confirmSuccess = false;
        })
        .catch(() => {
          this.confirmSuccess = false;
        });
    },
    confirmNewEmail() {
      this.confirmSuccess = false;
      this.$store
        .dispatch("profile/CONFIRM_NEW_EMAIL", this.$route.query.token)
        .then((res) => {
          if (res) {
            this.confirmSuccess = true;
            setTimeout(() => {
              if (!this.$store.state.isSocketConnected)
                this.$store.dispatch("CONNECT_TO_WS");
              this.$router.push({ name: "AnalyzesTable" });
            }, 3500);
          } else this.confirmSuccess = false;
        })
        .catch(() => {
          this.confirmSuccess = false;
        });
    },
  },
  created() {
    const token = this.$route.query.token as string;
    if (this.$route.query.token) {
      let decoded: IJWT;
      try {
        decoded = jwt_decode(token) as IJWT;
        if (decoded.exp && decoded.exp < Date.now() * 1000) {
          if (this.$route.path === "/account-confirmation") {
            this.confirmEmail();
          } else if (this.$route.path === "/email-confirmation") {
            this.confirmNewEmail();
          }
        } else {
          this.tokenError = "Confirmation link has expired";
        }
      } catch (e) {
        this.tokenError = "Confirmation link is invalid";
      }
    } else {
      this.tokenError = "Confirmation link is invalid. Token is missing";
    }
  },
  beforeDestroy() {
    this.clearError(null);
  },
});
