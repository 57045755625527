import Vue from "vue";
import { TARIFFS } from "@/utils/helper";
import { Tariff } from "@/types/shared-types";

export default Vue.extend({
  name: "TariffChangeModal",
  props: {
    dialog: {
      type: Boolean,
    },
    newTariff: {
      type: String as () => Tariff,
    },
  },
  data() {
    return {
      tariffs: TARIFFS,
    };
  },
  computed: {
    price(): number | "" {
      return this.newTariff ? TARIFFS[this.newTariff as Tariff].price : "";
    },
    currentTariff(): Tariff {
      return this.$store.state.profile.user.tariff;
    },
    priceDifference(): number {
      const { plans } = this.$store.state.profile.prices;
      const newPrice = this.price;
      const oldPrice = Number(plans[this.currentTariff]) || 0;

      if (typeof newPrice === "number") {
        return newPrice - oldPrice;
      }
      return 0;
    },
    modalText(): string {
      if (this.priceDifference > 0) {
        return `Are you sure you want to change your subscription plan? The subscription cost will be $${this.price} per month, payment will need to be made right away.`;
      }
      return `Are you sure you want to change your subscription plan right now? The subscription cost will be $${this.price} per month.`;
    },
  },
  methods: {
    changeTariff() {
      this.$store.dispatch("profile/SET_TARIFF_LOADING_ACTION", true);

      if (this.currentTariff === "freemium") {
        this.$store.dispatch("profile/SUBSCRIBE_TARIFF", this.newTariff);
      } else {
        this.$store.dispatch(`profile/CHANGE_TARIFF`, this.newTariff);
      }

      this.$emit("close");
    },
  },
});
