import Vue from "vue";

export default Vue.extend({
  name: "Features",

  data() {
    return {
      isIntersecting: false,
    };
  },
});
