import Vue from "vue";

export default Vue.extend({
  name: "Landing",
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
});
