import Vue from "vue";
import Footer from "@/components/Footer/index.vue";
import { ErrorsMessages } from "@/utils/errors";
import { SuccessMessages } from "@/utils/success-messages";

export default Vue.extend({
  name: "Auth",
  components: { Footer },
  data() {
    return {
      errorsMessages: ErrorsMessages,
      successMessages: SuccessMessages,
    };
  },
  computed: {
    errorCode(): number | null {
      return this.$store.state.errorAPI;
    },
    successCode(): number | null {
      return this.$store.state.successCode;
    },
  },
  methods: {
    clearError(code: number | null) {
      if (this.errorCode) this.$store.dispatch("CLEAR_API_ERROR_ACTION", code);
    },
  },
  created() {
    this.$vuetify.theme.dark = false;
    document.documentElement.className = "light-theme";
  },
  beforeDestroy() {
    this.clearError(null);
  },
});
