export interface IAnalysisProgressStatus {
  [key: string]: { text: string; value: number };
}

export interface ITableHeaders {
  visible: boolean;
  sortable: boolean;
  value: string;
  text?: string;
  class?: string;
  width?: string;
  align?: string;
}

export type FilterType =
  | "title"
  | "created"
  | "language"
  | "tonality_spec_detected"
  | "tonality_base_detected"
  | "keywords_spotted";

export interface IFilterItem {
  value: FilterType;
  text: string;
  disabled?: boolean;
}
export interface IFilterCondition {
  value: boolean;
  text: string;
}
export interface IFilter {
  subject: FilterType;
  condition: boolean;
  value: string;
}

export interface ITimeString {
  hours: string;
  minutes: string;
  seconds: string;
}

export const ANALYSIS_PROGRESS_STATUS: IAnalysisProgressStatus = {
  wait_analyze: {
    text: "Waiting for analysis",
    value: 0,
  },
  cropping_file: {
    text: "Step 1 of 9: Cropping file",
    value: 11,
  },
  uploading_file: {
    text: "Step 2 of 9: Uploading file",
    value: 22,
  },
  feature_extraction: {
    text: "Step 3 of 9: Feature extraction",
    value: 33,
  },
  segmentation: {
    text: "Step 4 of 9: Segmentation",
    value: 44,
  },
  embedding_evaluation: {
    text: "Step 5 of 9: Embedding evaluation",
    value: 55,
  },
  clustering: {
    text: "Step 6 of 9: Clustering",
    value: 66,
  },
  transcription: {
    text: "Step 7 of 9: Transcribing",
    value: 77,
  },
  tonality: {
    text: "Step 8 of 9: Transcribing",
    value: 88,
  },
  completed: {
    text: "Step 9 of 9: Finishing",
    value: 100,
  },
  failed: {
    text: "Error",
    value: 100,
  },
  aborted: {
    text: "Aborted",
    value: 100,
  },
};

export const TABLE_COLUMNS: ITableHeaders[] = [
  {
    text: "Title",
    sortable: false,
    value: "title",
    visible: true,
    width: "226px",
  },
  {
    text: "Speakers",
    sortable: false,
    value: "speakers_result",
    visible: true,
    width: "78px",
  },
  {
    text: "Topic",
    sortable: false,
    value: "topic",
    visible: true,
    width: "100px",
  },
  {
    text: "Sense",
    sortable: false,
    value: "sense",
    visible: true,
    width: "180px",
  },
  {
    text: "Sentiment",
    sortable: false,
    value: "tonality_base",
    visible: true,
    width: "110px",
  },
  {
    text: "Speech style",
    sortable: false,
    value: "tonality_spec",
    visible: true,
    width: "110px",
  },
  {
    text: "Key phrases",
    sortable: false,
    value: "keywords",
    visible: true,
    width: "94px",
  },
  {
    text: "Duration",
    sortable: false,
    value: "duration",
    visible: true,
    width: "80px",
  },
  {
    text: "Size",
    sortable: false,
    value: "size",
    visible: true,
    width: "100px",
  },
  {
    text: "Modified",
    sortable: false,
    value: "update_time",
    visible: true,
    width: "164px",
  },
  {
    text: "Created",
    sortable: false,
    value: "create_time",
    visible: false,
    width: "164px",
  },
  {
    text: "Language",
    sortable: false,
    value: "language",
    visible: false,
    width: "120px",
  },
  {
    text: "Action",
    value: "actions",
    sortable: false,
    align: "center",
    visible: true,
    width: "70px",
  },
];

export const FILTER_SUBJECTS = [
  { value: "title", text: "Title" },
  // { value: "created", text: "Created" },
  { value: "language", text: "Language" },
  { value: "tonality_spec_detected", text: "Speech style" },
  { value: "tonality_base_detected", text: "Sentiment" },
  { value: "keywords_spotted", text: "Keywords spotted" },
];

export const FILTER_CONDITIONS: IFilterCondition[] = [
  { value: true, text: "Contains" },
  { value: false, text: "Doesn't contain" },
];

export const LANGUAGE_COLLECTION = [
  { title: "English (US)", value: "en" },
  { title: "Russian (RU)", value: "ru" },
  { title: "Deutsch (DE)", value: "de", disabled: true },
  { title: "Italian (IT)", value: "it", disabled: true },
];

export const TONALITY_COLLECTION = [
  { title: "Positive", value: "positive" },
  { title: "Negative", value: "negative" },
  { title: "Neutral", value: "neutral" },
];

export const TONALITY_SPEC_COLLECTION = [
  { title: "Business", value: "business" },
  { title: "Intimate", value: "intimate" },
  { title: "Aggressive", value: "aggressive" },
];

export const tonalityTypes: { [key: string]: string } = {
  positive: "Positive",
  negative: "Negative",
  neutral: "Neutral",
  business: "Business",
  intimate: "Intimate",
  aggressive: "Aggressive",
};

export const PERSON_COLORS = [
  "var(--person-1)",
  "var(--person-2)",
  "var(--person-3)",
  "var(--person-4)",
  "var(--person-5)",
  "var(--person-6)",
  "var(--person-7)",
  "var(--person-8)",
  "var(--person-9)",
  "var(--person-10)",
  "var(--person-11)",
  "var(--person-12)",
];
export const PERSON_CONTRAST_COLORS = [
  "var(--person-1-contrast)",
  "var(--person-2-contrast)",
  "var(--person-3-contrast)",
  "var(--person-4-contrast)",
  "var(--person-5-contrast)",
  "var(--person-6-contrast)",
  "var(--person-7-contrast)",
  "var(--person-8-contrast)",
  "var(--person-9-contrast)",
  "var(--person-10-contrast)",
  "var(--person-11-contrast)",
  "var(--person-12-contrast)",
];

export function storageProgressStatus(val: number) {
  if (val <= 70) return storageVariants.SUCCESS;
  if (val > 70 && val <= 95) return storageVariants.WARNING;
  if (val > 96 && val < 100) return storageVariants.ALERT;
  else return storageVariants.FULL;
}

export enum storageVariants {
  SUCCESS = "success",
  WARNING = "warning",
  ALERT = "alert",
  FULL = "full",
}

export const Months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const storageColors = {
  [storageVariants.SUCCESS]: "#4CAF50",
  [storageVariants.WARNING]: "#FB8C00",
  [storageVariants.ALERT]: "#FF5252",
  [storageVariants.FULL]: "#FF5252",
};

export const storageInfo = {
  [storageVariants.WARNING]:
    "The storage space for the analysis results is running out.",
  [storageVariants.ALERT]:
    "The storage is almost full. When the space runs out, it will not be possible to save test results.",
  [storageVariants.FULL]:
    "The storage is full. The analysis results cannot be saved",
};

export const storageInfoInSettings = {
  [storageVariants.ALERT]: {
    title: "The storage is almost full",
    description:
      "When the space runs out, it will not be possible to save test results",
  },
  [storageVariants.FULL]: {
    title: "The storage is full",
    description: "The analysis results cannot be saved",
  },
};

export enum audioErrorTypes {
  STORAGE_CONTINUE = "upgrade_storage_continue",
  TARIFF_CONTINUE = "upgrade_tariff_continue",
  STORAGE = "upgrade_storage",
  TARIFF = "upgrade_tariff",
}

export const audioErrors = {
  [audioErrorTypes.STORAGE_CONTINUE]: {
    title: "Some files cannot be uploaded.",
    description:
      "It needs an additional $value$ to analyze the selected files. Upgrade storage and upload again. You can continue without adding space, then the unloaded files will not be analyzed.",
  },
  [audioErrorTypes.TARIFF_CONTINUE]: {
    title: "Some files cannot be uploaded.",
    description:
      "You have reached the limit of $value$ analyses per month available at your plan. Upgrade your plan and upload again. You can continue without upgrade, then the unloaded files will not be analyzed.",
  },
  [audioErrorTypes.STORAGE]: {
    title: "Files cannot be uploaded.",
    description:
      "It needs an additional $value$ to analyze the selected files. Upgrade storage and upload again.",
  },
  [audioErrorTypes.TARIFF]: {
    title: "Files cannot be uploaded.",
    description:
      "You have reached the limit of $value$ analyses per month available at your plan. Upgrade your plan and upload again.",
  },
};

export enum functionTypes {
  MORE_ANALYSIS = "More analysis each month",
  LONG_AUDIO = "Analysis of long audio recordings",
  EXPENDED_STORAGE = "Expanded storage",
  TONALITY_SENTIMENT = "Tonality sentiment detection",
  TONALITY_SPEECH = "Tonality speech detection",
  TOPIC = "Topic detection",
  SUBJECT = "Sense detection",
  INTERRUPTIONS = "Interruption analysis",
  KEYWORDS = "Keywords and phrases searches",
}

export const TARIFFS = {
  freemium: {
    price: 0,
    req_per_month: 10,
    duration_record: 120,
    storage: 52428800,
    display_storage: "50 MB",
    sense: false,
    topic: false,
    overlap: false,
  },
  standart: {
    price: 10,
    req_per_month: 100,
    duration_record: 300,
    storage: 1073741824,
    display_storage: "1 GB",
    topic: true,
    sense: false,
    overlap: false,
  },
  standart_plus: {
    price: 19,
    req_per_month: 200,
    duration_record: 600,
    storage: 10737418240,
    display_storage: "10 GB",
    topic: true,
    sense: true,
    overlap: false,
  },
  premium: {
    price: 99,
    req_per_month: 500,
    duration_record: 3600,
    storage: 107374182400,
    display_storage: "100 GB",
    topic: true,
    sense: true,
    overlap: true,
  },
};

export const tariffUpgradeInfo = {
  [functionTypes.TONALITY_SENTIMENT]: {
    title: "Upgrade to use Tonality detection",
    description:
      "The Topic function allows you to understand what the conversation was about in general. Upgrade and get more features:",
  },
  [functionTypes.TONALITY_SPEECH]: {
    title: "Upgrade to use Tonality detection",
    description: "",
  },
  [functionTypes.TOPIC]: {
    title: "Upgrade to use Topic detection",
    description: "",
  },
  [functionTypes.SUBJECT]: {
    title: "Upgrade to use Sense detection",
    description: "",
  },
  [functionTypes.INTERRUPTIONS]: {
    title: "Upgrade to use Interruption analysis",
    description: "",
  },
};

export const TariffName = {
  freemium: "Freemium",
  standart: "Standart",
  standart_plus: "Standart plus",
  premium: "Premium",
};

export enum SizeSign {
  MB = "mb",
  GB = "gb",
  TB = "tb",
}

export const STORAGE_PRICING = {
  [SizeSign.MB]: 0.01,
  [SizeSign.GB]: 1.5,
  [SizeSign.TB]: 100,
};

export function calculateTime(
  time: number,
  coef: number
): { min: number; sec: number } {
  const min = Math.floor((+time * coef) / 60);
  const sec = Math.floor(+time * coef - min * 60);
  return { min, sec };
}

export function calculateScale(audioLength: number): number {
  const baseAudioLenght = 900;
  const coefficient = 9;
  return audioLength < baseAudioLenght ? 100 : audioLength / coefficient;
}

export function formatAMPM(date: string) {
  const d = new Date(date);
  let h = d.getHours();
  const m = d.getMinutes();
  const ampm = h >= 12 ? "PM" : "AM";
  h = h % 12;
  h = h ? h : 12;
  const minutes = m < 10 ? `0${m}` : m;
  const hours = h < 10 ? `0${h}` : h;
  return { hours, minutes, ampm };
}

export function formatEU(date: string) {
  const d = new Date(date);
  const hours = d.getHours();
  const m = d.getMinutes();
  const minutes = m < 10 ? `0${m}` : m;
  return { hours, minutes };
}

export function dateFormat(date: string): {
  month: string;
  day: string | number;
  year: number;
} {
  const d = new Date(date);
  const month = Months[d.getMonth()];
  const dayNonFormat = d.getDate();
  const day = dayNonFormat < 10 ? `0${dayNonFormat}` : dayNonFormat;
  const year = d.getFullYear();
  return { month, day, year };
}

export function formatDateByTimezone(date: string): string {
  const { month, day, year } = dateFormat(date);
  /* if (true) {*/
  const { hours, minutes, ampm } = formatAMPM(date);
  return `${month} ${day} ${year} ${hours}:${minutes} ${ampm}`; /*
  } else {
    const { hours, minutes } = formatEU(date);
    return `${day} ${month} ${year} at ${hours}:${minutes}`;
  }*/
}

export function timeFormat(time: number, coef: number): string {
  const { min, sec } = calculateTime(time, coef);
  return `${min}:${sec < 10 ? `0${sec}` : sec}`;
}

/**
 *
 * @param length length time in ms
 * @returns formatted string with omited unneeded registers, like `1d 7h 5m 24s` or `34m 3s`
 */
export function formatLength(length: number): string {
  const d = Math.floor(length / 86400000);
  const h = Math.floor((length - d * 86400000) / 3600000);
  const m = Math.floor((length - d * 86400000 - h * 3600000) / 60000);
  const s = Math.ceil((length - d * 86400000 - h * 3600000 - m * 60000) / 1000);
  return Object.entries({ d, h, m, s })
    .filter(
      ([, value], idx, arr) =>
        arr.filter((_, i) => i < idx).some(([, v]) => v > 0) || value > 0
    )
    .map(([key, value]) => `${value}${key}`)
    .join(" ");
}

export function dateDiffMs(start: string, end: string): number {
  const [startTimestamp, endTimestamp] = [start, end].map((v) =>
    new Date(v).getTime()
  );
  return [startTimestamp, endTimestamp].some(isNaN)
    ? 0
    : endTimestamp - startTimestamp;
}

export function sizeFormat(size: number): string {
  if (size < 0) return `${"00.00 MB"}`;

  const type = ["KB", "MB", "GB", "TB"];
  let i = 0;

  while (Math.floor(size / 1024) && i < type.length - 1) {
    size /= 1024;
    i++;
  }

  const dType = type[i];
  const dSize = size.toFixed(dType === type[0] ? 0 : 2);

  return `${dSize} ${dType}`;
}

export function msOrSecFormat(length: number): string {
  if (length < 1) return `${(length * 1000).toFixed()}ms`;
  return length > 60
    ? `${timeFormat(length, 1)}m`
    : `${timeFormat(length, 1)}s`;
}

export function getZoomCode(): string | null {
  return localStorage.getItem("code");
}

export function removeZoomCode(): void {
  localStorage.removeItem("code");
}

export function getGlobalTheme(): string {
  return localStorage.getItem("theme") || "light-theme";
}
export function setGlobalTheme(theme: string): void {
  localStorage.setItem("theme", theme);
}

export function getCookie(name: string): string | undefined {
  const matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" + name.replace(/([.$?*|{}()[\]\\/+^])/g, "\\$1") + "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function deleteCookie(name: string): void {
  setCookie(name, "", {
    "max-age": -1,
  });
}

export function keywordsToArray(keywords: string): string[] {
  return keywords
    .replace(/\s+/g, " ")
    .split(",")
    .map((key) => key.trim())
    .filter(Boolean);
}

export function checkPunctuation(word: string): [string, string] {
  const hasPunctuation = /[\\wа-яА-Яa-zA-Z]+[.,!?;:]$/i;
  if (hasPunctuation.test(word)) {
    const punctuationMark = word.split("").pop();
    const newWord = word.slice(0, -1);
    return [newWord, punctuationMark + " "];
  } else {
    return [word, " "];
  }
}

export function setCookie(
  name: string,
  value: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any = {}
): void {
  options = {
    path: "/",
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie =
    encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (const optionKey in options) {
    updatedCookie += "; " + optionKey;
    const optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

export function convertSeconds(seconds: number): string {
  if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    return minutes > 1 ? `${minutes} minutes` : `${minutes} minute`;
  }

  const hours = Math.floor(seconds / 3600);
  return hours > 1 ? `${hours} hours` : `${hours} hour`;
}

export function timeStringToSeconds(time: ITimeString): number {
  const hoursInSeconds = Number(time.hours) * 3600;
  const minutesInSeconds = Number(time.minutes) * 60;
  const seconds = Number(time.seconds);

  return hoursInSeconds + minutesInSeconds + seconds;
}

export const formatNumber = (num: number): string => `${num}`.padStart(2, "0");

export function secondsToTimeString(totalSeconds: number): ITimeString {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return {
    hours: formatNumber(hours),
    minutes: formatNumber(minutes),
    seconds: formatNumber(seconds),
  };
}

export function getTimeDifference(startTime: number, endTime: number): number {
  return Math.abs(endTime - startTime);
}

export function removePunctuationMark(word: string): string {
  return word.replace(/[.,!?;:]$/i, "");
}
