import Vue from "vue";
import { IZoomRecord } from "@/store/zoom/zoom-types";
import { TARIFFS, dateDiffMs, formatLength } from "@/utils/helper";
import { Tariff } from "@/types/shared-types";

interface IEditedZoomRecord extends IZoomRecord {
  length: number;
  formattedLength: string;
}

export default Vue.extend({
  name: "RecordsTable",
  props: {
    zoomRecords: {
      type: Array as () => IZoomRecord[],
    },
    uploadedRecord: {
      type: Array as () => string[],
    },
    isUploadFiles: {
      type: Boolean,
    },
  },
  data() {
    return {
      hoveredItem: <string | null>null,
      page: 1,
      pageCount: 1,
      tariffs: TARIFFS,
      headers: [
        {
          text: "Title",
          align: "start",
          sortable: false,
          value: "meeting_topic",
        },
        { text: "Length", value: "length", sortable: true },
        { text: "Size", value: "size", sortable: true },
        { text: "Date", value: "recording_end", sortable: true },
      ],
    };
  },
  computed: {
    analyzesAvailable(): number {
      return this.$store.getters["profile/GET_ANALYZES_AVAILABLE"];
    },
    storageAvailable(): number {
      return this.$store.getters["profile/GET_STORAGE_AVAILABLE"];
    },
    modifiedRecordsArray(): IEditedZoomRecord[] {
      return this.zoomRecords?.map((r) => {
        const length = dateDiffMs(r.recording_start, r.recording_end);
        return { ...r, length, formattedLength: formatLength(length) };
      });
    },
    isRecordUploading(): boolean {
      return this.$store.state.zoom.isRecordUploading;
    },
    tariff(): Tariff {
      return this.$store.state.profile.user.tariff;
    },
  },
  methods: {
    validateFile(file: IZoomRecord) {
      const duration =
        dateDiffMs(file.recording_start, file.recording_end) / 1000;

      if (!duration) return "An error occurred reading the file";

      const isValidDuration =
        duration <= this.tariffs[this.tariff].duration_record;

      if (!isValidDuration)
        return `Audio file is too long for ${this.tariff
          .split("_")
          .join(" ")} plan`;

      return "";
    },
    uploadFile(item: IZoomRecord) {
      if (this.isRecordUploading) return;
      const newItem = {
        file: { size: item.size, name: item.meeting_topic, id: item.id },
        status: false,
        error: this.validateFile(item),
      };
      this.$emit("uploadZoomFile", newItem);
    },
    openTask(id: number) {
      this.$router.push({ name: "Analysis", params: { id: id.toString() } });
    },
    setHovered(item: IZoomRecord) {
      this.hoveredItem = item.id;
    },
  },
});
