import Vue from "vue";
import Header from "./Header/index.vue";
import RootHeader from "./RootHeader/index.vue";
import Features from "./Features/index.vue";
import Cases from "./Cases/index.vue";
import Presentation from "./Presentation/index.vue";
import ForInvestors from "./ForInvestors/index.vue";
import ZoomApp from "./ZoomApp/index.vue";
import Contacts from "./Contacts/index.vue";
import Register from "./Register/index.vue";
import Footer from "./Footer/index.vue";
import WebexApp from "./WebexApp/index.vue";

export default Vue.extend({
  name: "Landing",

  metaInfo() {
    return {
      meta: [
        {
          name: "viewport",
          content: "width=device-width,initial-scale=1.0",
        },
      ],
    };
  },

  components: {
    Header,
    RootHeader,
    Features,
    Cases,
    Presentation,
    ForInvestors,
    ZoomApp,
    Contacts,
    Register,
    Footer,
    WebexApp,
  },

  data() {
    return {
      activeMenuItem: "home",
    };
  },

  mounted() {
    if (window.innerWidth >= 768) {
      window.addEventListener("scroll", () => {
        for (const key of Object.keys(this.$refs)) {
          if (this.$refs[key]) {
            const offsetTop = ((this.$refs[key] as Vue).$el as HTMLElement)
              .offsetTop;

            if (offsetTop - offsetTop * 0.1 <= window.scrollY)
              this.activeMenuItem = key;
          }
        }
      });
    }
  },

  methods: {
    scrollTo(str: string) {
      const to = ((this.$refs[str] as Vue).$el as HTMLElement).offsetTop;
      window.scrollTo({ top: to - 80, behavior: "smooth" });
    },
  },
});
