var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"summary-title"},[_vm._v("Speakers")]),_c('v-divider',{staticClass:"divider"}),(
      _vm.$storeAnalysis.timelineData &&
      _vm.$storeAnalysis.timelineData.main &&
      _vm.$storeAnalysis.result &&
      !_vm.showAbortAlert &&
      !_vm.showLoader
    )?_c('div',[_c('v-row',{staticClass:"summary-row",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"sp-header-button"},[_c('v-btn',{staticClass:"text-btn",attrs:{"x-small":"","text":"","color":"primary","disabled":!_vm.$storeAnalysis.timelineData.main},on:{"click":function($event){_vm.hasSelect ? _vm.setSelectedToAll(false) : _vm.setSelectedToAll(true)}}},[_vm._v(" "+_vm._s(_vm.hasSelect ? "Deselect all" : "Select all")+" ")]),_c('v-btn',{staticClass:"text-btn ml-4",attrs:{"x-small":"","text":"","color":"primary"},on:{"click":_vm.openRenameSpeakersDialog}},[_vm._v(" Rename ")])],1),_c('v-col',{staticClass:"sp-header-label"},[_vm._v(" Duration ")])],1),_vm._l((_vm.$storeAnalysis.timelineData.main),function(speaker){return _c('v-row',{key:("speaker-" + (speaker.speaker)),staticClass:"summary-row",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"sp-body-checkbox-block"},[_c('v-checkbox',{staticClass:"sp-body-checkbox",attrs:{"color":"primary","hide-details":"","dense":""},model:{value:(speaker.visible),callback:function ($$v) {_vm.$set(speaker, "visible", $$v)},expression:"speaker.visible"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"sp-body-label"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getSpeakerName(speaker.speaker))+" ")])]}}],null,true)},[_c('span',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(_vm.getSpeakerName(speaker.speaker)))])])],1),_c('v-col',{staticClass:"sp-body-proc"},[_vm._v(" "+_vm._s((speaker.activity / _vm.coefficient).toFixed())+"% ")])],1)}),(_vm.renameDialog)?_c('RenameSpeakersModal',{attrs:{"dialog":_vm.renameDialog},on:{"update:dialog":function($event){_vm.renameDialog=$event},"close":function($event){_vm.renameDialog = false}}}):_vm._e()],2):_vm._e(),(_vm.showLoader)?_c('div',{staticClass:"summary-value mt-2"},[_vm._v("Detecting...")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }