import { Module } from "vuex";
import axios from "axios";
import { RootState } from "@/store/store-types";
import { IWebexState, IWebexRecord } from "@/store/webex/webex-types";
import { setErrorMessage } from "@/utils/errors";

const api = process.env.VUE_APP_API_XHR_PROTOCOL + process.env.VUE_APP_API_HOST;
const getState = (): IWebexState => ({
  meetings: Array<IWebexRecord>(),
  isMeetingsLoading: false,
  isRecordUploading: false,
});

export const webex: Module<IWebexState, RootState> = {
  namespaced: true,
  state: getState,
  mutations: {
    SET_WEBEX_MEETINGS(state: IWebexState, meetings: []) {
      state.meetings = meetings;
    },
    SET_MEETINGS_LOADING(state: IWebexState, payload: boolean) {
      state.isMeetingsLoading = payload;
    },
    SET_RECORD_UPLOAD_LOADING(state: IWebexState, payload: boolean) {
      state.isRecordUploading = payload;
    },
  },
  actions: {
    CONFIRM_WEBEX_ACCOUNT(_, code: string) {
      return axios
        .post(`${api}/api/webex/signIn`, { code })
        .then((res) => {
          return res?.data?.response;
        })
        .catch((err) => {
          if (err?.response?.data?.error) {
            throw new Error(err.response.data.error.message);
          } else {
            throw new Error(err);
          }
        });
    },
    GET_WEBEX_MEETINGS({ commit }) {
      commit("SET_MEETINGS_LOADING", true);

      axios
        .get(`${api}/api/webex/records`)
        .then((res) => {
          if (res?.data?.response?.records) {
            commit("SET_WEBEX_MEETINGS", res.data.response.records);
          }
        })
        .catch((err) => {
          console.log("MEETINGS err", err);
        })
        .finally(() => {
          commit("SET_MEETINGS_LOADING", false);
        });
    },
    UPLOAD_WEBEX_RECORD({ commit }, record_id) {
      commit("SET_RECORD_UPLOAD_LOADING", true);
      return axios
        .post(`${api}/api/webex/uploadAnalyze`, { record_id })
        .then((res) => {
          commit("SET_RECORD_UPLOAD_LOADING", false);
          if (res?.data?.response?.file_id) return res.data.response;
        })
        .catch((err) => {
          commit("SET_RECORD_UPLOAD_LOADING", false);
          if (err?.response?.data?.error?.code) {
            const errCode: number = err?.response?.data?.error?.code;
            throw new Error(setErrorMessage(errCode));
          } else {
            throw new Error(setErrorMessage(-2));
          }
        });
    },
  },
};
