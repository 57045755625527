var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{on:{"mouseleave":function($event){_vm.hoveredItem = null}}},[_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.headers,"items":_vm.modifiedRecordsArray,"page":_vm.page,"items-per-page":10,"hide-default-footer":"","loading":_vm.isRecordUploading},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event},"click:row":_vm.setHovered},scopedSlots:_vm._u([{key:"item.topic",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"hover-span",on:{"mouseenter":function($event){return _vm.setHovered(item)}}}),_vm._v(" "+_vm._s(item.topic)+" "),_c('br'),_c('span',[_vm._v("ID "+_vm._s(item.id))])]}},{key:"item.formattedLength",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(item.formattedLength))])]}},{key:"item.formattedSize",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.formattedSize)+" ")])]}},{key:"item.timeRecorded",fn:function(ref){
var item = ref.item;
return [(_vm.uploadedRecord.includes(item.id))?_c('div',[_vm._v("Uploaded")]):(_vm.hoveredItem !== item.id)?_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(new Date(item.timeRecorded).toLocaleString())+" ")]):_c('div',{staticClass:"d-flex align-center justify-end",staticStyle:{"height":"100%"}},[(item.task_id)?_c('v-btn',{staticClass:"mr-1",attrs:{"x-small":"","color":"primary","depressed":""},on:{"click":function($event){return _vm.openTask(item.id)}}},[_vm._v(" Open ")]):_vm._e(),_c('v-btn',{attrs:{"color":"primary","depressed":"","x-small":"","disabled":_vm.isRecordUploading || !_vm.isUploadFiles,"loading":_vm.isRecordUploading},on:{"click":function($event){return _vm.uploadWebexFile(item)}}},[_vm._v(" Upload ")])],1)]}}])}),_c('v-pagination',{staticClass:"pagination",attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }