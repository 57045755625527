























import Vue from "vue";
import Header from "@/components/Header/index.vue";
import Footer from "@/components/Footer/index.vue";
import Snackbar from "@/components/modals/Snackbar/index.vue";
import { getCookie, getGlobalTheme } from "@/utils/helper";
export default Vue.extend({
  name: "App",
  components: { Footer, Header, Snackbar },
  data: () => ({
    isConnectError: false,
  }),
  computed: {
    isAuth() {
      return this.$route.matched.some((record) => record.meta.authRequired);
    },
    isLanding() {
      const landingRoutes = [
        "Landing",
        "Login",
        "Register",
        "Forgot",
        "AccountDeleted",
        "WebexConfirmation",
        "AccountConfirmation",
        "ZoomConfirmation",
      ];

      return this.$route.name
        ? landingRoutes.includes(this.$route.name)
        : false;
    },
    showGlobalHeader(): boolean {
      return this.$route.name
        ? this.isAuth &&
            !["PaymentResult", "PaymentCanceled", "PaymentProcessing"].includes(
              this.$route.name
            )
        : this.isAuth;
    },
    showGlobalFooter(): boolean {
      if (this.$route.name) {
        return (
          this.isAuth &&
          ![
            "AnalysisMain",
            "AnalysisSpeakersStatistics",
            "AccountConfirmation",
          ].includes(this.$route.name)
        );
      } else return false;
    },
  },
  mounted() {
    window.addEventListener("online", () => {
      this.isConnectError = false;
      const accessToken = getCookie("access-token");
      const refresh = getCookie("refresh-token");

      if (accessToken && refresh) {
        this.$store.dispatch("CONNECT_TO_WS");
      }
    });

    window.addEventListener("offline", () => {
      this.isConnectError = true;
      this.$store.dispatch("DISCONNECT_WS");
    });
  },
  methods: {
    setTheme() {
      const theme = getGlobalTheme();
      this.$store.dispatch("SET_THEME", theme);
    },
  },
  created() {
    const accessToken = getCookie("access-token");
    const refresh = getCookie("refresh-token");

    if (accessToken && refresh) {
      this.$store.dispatch("CONNECT_TO_WS");
    }
    this.setTheme();
  },
});
