import Vue from "vue";
import HeaderProgress from "@/components/Header/HeaderProgress/index.vue";
import {
  storageProgressStatus,
  storageColors,
  storageVariants,
  storageInfo,
  setGlobalTheme,
  getGlobalTheme,
  dateFormat,
} from "@/utils/helper";
import { SuccessMessages } from "@/utils/success-messages";
import { Tariff } from "@/types/shared-types";
import { IUser } from "@/store/profile/profile-types";
import { ErrorsMessages } from "@/utils/errors";

export default Vue.extend({
  name: "Header",
  props: {
    isConnectError: {
      type: Boolean,
    },
  },
  components: { HeaderProgress },
  data() {
    return {
      storageColors: storageColors,
      storageInfo: storageInfo,
      analysisAllowedCount: 10,
      analysisCurrentCount: 3,
      replySignUpDisabled: false,
      currentTheme: this.$vuetify.theme.dark,
      savedTheme: getGlobalTheme(),
      successMessages: SuccessMessages,
      errorsMessages: ErrorsMessages,
    };
  },
  computed: {
    currentUser(): IUser {
      return this.$store.state.profile.user;
    },
    tariff(): Tariff {
      return this.currentUser.tariff;
    },
    infoStorageAvailable() {
      return this.$store.getters["profile/GET_INFO_STORAGE_AVAILABLE"];
    },
    infoAnalyzesAvailable() {
      return this.$store.getters["profile/GET_INFO_ANALYZES_AVAILABLE"];
    },
    storageVolumePercent(): number {
      return this.$store.getters["profile/GET_STORAGE_VOLUME_PERCENT"];
    },
    storageStatus(): storageVariants {
      return storageProgressStatus(this.storageVolumePercent);
    },
    showStorageInfo(): boolean {
      return [
        storageVariants.ALERT,
        storageVariants.WARNING,
        storageVariants.FULL,
      ].includes(this.storageStatus);
    },
    analyzesCountStatus() {
      if (this.currentUser.email_confirmed === false) return "not-confirmed";
      else if (this.analysisCurrentCount === 0) return "empty";
      else return "";
    },
    getNextPaymentDate(): string {
      const date = new Date(
        this.currentUser.tariff_period_end * 1000
      ).toString();
      const { month, day } = dateFormat(date);

      return `${month} ${day}`;
    },
    successCode(): number | null {
      return this.$store.state.successCode;
    },
    APIError(): number | null {
      return this.$store.state.errorAPI;
    },
  },
  methods: {
    logout() {
      localStorage.removeItem("theme");
      this.$store.dispatch("profile/LOGOUT_USER");
      this.$router.push({ name: "Landing" });
      window.scrollTo({ top: 0 });
    },
    changeTheme(theme: string) {
      if (this.savedTheme === theme) return;
      this.savedTheme = theme;
      setGlobalTheme(theme);
      this.$store.dispatch("SET_THEME", theme);
    },
    async replyConfirmSignUp() {
      if (this.APIError === 2829294403) return;

      const res = await this.$store.dispatch("profile/REPLY_CONFIRM_EMAIL");

      if (res) {
        this.$store.dispatch("SET_SUCCESS_ALERT", 4);
      } else {
        setTimeout(() => {
          this.$store.dispatch("CLEAR_API_ERROR_ACTION", null);
        }, 5000);
      }
    },
  },
  beforeDestroy() {
    if (this.APIError) this.$store.dispatch("CLEAR_API_ERROR_ACTION", null);
  },
});
