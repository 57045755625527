import Vue from "vue";

export default Vue.extend({
  name: "Header",
  props: ["activeMenuItem"],
  data() {
    return {
      menuItems: <{ text: string; anchor: string }[]>[
        {
          text: "Home",
          anchor: "home",
        },
        {
          text: "Features",
          anchor: "features",
        },
        {
          text: "Business cases",
          anchor: "cases",
        },
        {
          text: "Why we",
          anchor: "presentation",
        },
        {
          text: "For investors",
          anchor: "forInvestors",
        },
        {
          text: "Zoom APP",
          anchor: "zoomApp",
        },
        {
          text: "Webex",
          anchor: "webexApp",
        },
        {
          text: "Contact",
          anchor: "contacts",
        },
      ],
    };
  },
});
