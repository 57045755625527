import Vue from "vue";
import Footer from "@/components/Footer/index.vue";
import { ErrorsMessages } from "@/utils/errors";
import {
  EMAIL_VALIDATORS,
  PASSWORD_VALIDATORS,
  VLIDATORS,
} from "@/utils/validators";
import { getZoomCode } from "@/utils/helper";
import { GET_ZOMBTY_AUTH_URL, SHOW_ZOMBTY_LOGIN } from "@/utils/consts";
import { openZombtyWindow } from "@/utils/oauth";

export default Vue.extend({
  name: "Auth",
  components: { Footer },
  data() {
    return {
      form: {
        email: "",
        password: "",
        captcha: "",
      },

      rules: VLIDATORS,
      passwordValidators: PASSWORD_VALIDATORS,
      emailValidators: EMAIL_VALIDATORS,
      showPass: false,
      errorsMessages: ErrorsMessages,

      zombtyOpened: false,
      zombtyUnsub: <null | (() => void)>null,
    };
  },
  methods: {
    async submit() {
      this.clearError(null);
      const isValid = (
        this.$refs.form as Vue & { validate: () => boolean }
      ).validate();
      if (isValid) {
        this.$store.dispatch("profile/SET_LOGIN_LOADING_ACTION", true);
        await this.$recaptchaLoaded();
        this.form.captcha = await this.$recaptcha("login");
        const code = getZoomCode();
        const userData = { ...this.form, ...(code && { code }) };
        this.$store.dispatch("profile/LOGIN_USER", userData);
      }
    },
    clearError(code: number | null) {
      if (this.errorCode) this.$store.dispatch("CLEAR_API_ERROR_ACTION", code);
    },
    openZombtyLogin() {
      this.zombtyOpened = true;
      this.zombtyUnsub = openZombtyWindow({
        url: this.zombtyLoginUrl,
        onSuccess: (state) =>
          this.$store.dispatch("profile/LOGIN_THROUGH_ZOMBTY", state),
        onOtherTabSuccess: () => setTimeout(() => location.reload(), 2000),
        onClosed: () => (this.zombtyOpened = false),
      });
    },
  },
  computed: {
    loading(): boolean {
      return this.$store.state.profile.isLoginLoading;
    },
    errorCode(): number | null {
      return this.$store.state.errorAPI;
    },
    zombtyLoginUrl(): string {
      return GET_ZOMBTY_AUTH_URL(this.$router);
    },
    showZombtyLoginButton() {
      return SHOW_ZOMBTY_LOGIN;
    },
  },
  beforeDestroy() {
    this.clearError(null);
    this.zombtyUnsub?.();
  },
  beforeRouteLeave(to, from, next) {
    if (!this.loading) {
      next();
    }
  },
});
