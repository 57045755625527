import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import ZoomIcon from "@/components/ZoomIcon/index.vue";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: {
      zoom: {
        component: ZoomIcon,
      },
    },
  },
});
