import { IOverlap } from "@/store/analyzes/analyzes-types";
import { PERSON_CONTRAST_COLORS } from "@/utils/helper";
import Vue from "vue";
import TonalityMetrics from "./TonalityMetrics/index.vue";

export default Vue.extend({
  name: "PersonCard",

  props: ["person", "personIndex", "coefficient", "tonalities"],

  components: { TonalityMetrics },

  data() {
    return {
      contrastColors: PERSON_CONTRAST_COLORS,
    };
  },

  computed: {
    interruptions(): IOverlap[] | null {
      return this.$store.state.analyzes.currentAnalyze.overlap;
    },
  },
  methods: {
    getSpeakerName(speaker: number): string {
      return (
        this.$store.state.analyzes.currentAnalyze.speakers_names[speaker] ||
        `Person ${speaker}`
      );
    },
  },
});
