import Vue from "vue";
import { Analysis, Tonality } from "@/store/analyzes/analyzes-types";
import { PERSON_CONTRAST_COLORS } from "@/utils/helper";
import PersonCard from "./PersonCard/index.vue";

export default Vue.extend({
  name: "AnalysisSpeakersStatistics",
  components: { PersonCard },
  data() {
    return {
      contrastColors: PERSON_CONTRAST_COLORS,
    };
  },
  computed: {
    $storeAnalysis(): Analysis {
      return this.$store.state.analyzes.currentAnalyze;
    },
    $APIError(): number | null {
      return this.$store.state.errorAPI;
    },
    $WSError(): number | null {
      return this.$store.state.errorWS;
    },
    coefficient(): number {
      return this.$store.getters["analyzes/GET_COEFFICIENT"];
    },
  },
  methods: {
    getRoundProc(activity: number) {
      return Math.round(activity / this.coefficient);
    },
    clearWSError(code: number | number[] | null) {
      if (this.$WSError) this.$store.dispatch("CLEAR_WS_ERROR_ACTION", code);
    },
    clearAPIError(code: number | number[] | null) {
      if (this.$APIError) this.$store.dispatch("CLEAR_API_ERROR_ACTION", code);
    },
    getTotalPersonTonality(idxPerson: number, tonalitArr: any) {
      let sum = 0;
      tonalitArr.forEach((el: [string, number]) => {
        sum += el[1] as number;
      });
      return sum;
    },
    getPersonTonality(idx: number, tonalityArr: any) {
      const tonality: Array<Tonality> = tonalityArr.map(
        (el: [string, number]) => {
          return { name: el[0] as string, value: el[1] as number };
        }
      );
      let other = 100;
      const res = [];
      for (let i = 0; i < 3; i++) {
        const value = Math.floor(
          (tonality[i].value / this.getTotalPersonTonality(idx, tonalityArr)) *
            100
        );
        const resValue = tonality[i].value > 0.005 && value === 0 ? 1 : value;
        other -= resValue;
        if (resValue > 0) res.push({ name: tonality[i].name, value: resValue });
      }
      if (other > 0) res.push({ name: "Other", value: other >= 0 ? other : 0 });

      return res;
    },
    getSpeakerName(speaker: number): string {
      return this.$storeAnalysis.speakers_names[speaker] || `Person ${speaker}`;
    },
  },
});
