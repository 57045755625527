import Vue from "vue";
import VueI18n from "vue-i18n";
import * as en from "@/locales/en";
import vuetify from "@/plugins/vuetify";
import { noop } from "@/utils/utils";

const loadedLanguages = ["en"];

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: { en },
  pluralizationRules: {
    ru: (choice, choicesLength) => {
      // this === VueI18n instance, so the locale property also exists here

      if (choice === 0) {
        return 0;
      }

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) {
        return !teen && endsWithOne ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return choicesLength < 4 ? 2 : 3;
    },
  },
});

const loadI18nLocale = (locale: string) =>
  loadedLanguages.includes(locale)
    ? Promise.resolve()
    : import(
        /* webpackChunkName: "lang-[request]" */ /* webpackMode: "eager" */ `@/locales/${locale}`
      ).then((data) => {
        i18n.setLocaleMessage(locale, data);
      });

const loadVuetifyLocale = (locale: string) =>
  loadedLanguages.includes(locale)
    ? Promise.resolve()
    : import(
        /* webpackChunkName: "lang-[request]" */ /* webpackMode: "eager" */ `vuetify/lib/locale/${locale}`
      )
        .then((data) => {
          Vue.set(vuetify.framework.lang.locales, locale, data.default);
        })
        .catch(noop);

const setLocale = async (locale: string) => {
  const i18nLocale = loadI18nLocale(locale).then(() => (i18n.locale = locale));

  const vuetifyLocale = loadVuetifyLocale(locale).then(
    () => (vuetify.framework.lang.current = locale)
  );

  await Promise.all([i18nLocale, vuetifyLocale]).then(() => {
    loadedLanguages.push(locale);
  });
};

Vue.prototype.$setLocale = setLocale;

export default i18n;

declare module "vue/types/vue" {
  interface VueConstructor {
    $setLocale: typeof setLocale;
  }
}
