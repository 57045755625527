export function setErrorMessage(code: number): string {
  return ErrorsMessages[code] || "Unknown error";
}

export enum ErrorsMessages {
  "An error occurred on the server. Please, try again" = 0, // -1
  "Invalid params" = 2992797558, // 1
  "jwt is missing" = 2518430830, // 2
  "jwt invalid" = 3617972529, // 3
  "jwt expired" = 1670060043, // 4
  "Email is invalid" = 1291113222, // 5
  "Firstname is invalid" = 3997089381, // 6
  "Lastname is invalid" = 2727173949, // 7
  "Old and new passwords equal" = 3693768663, // 8
  "Password is invalid" = 2790622315, // 9
  "New password invalid" = 3521455829, // 10
  "Task id is invalid" = 1126712056, // 11
  "Title is invalid" = 3373988824, // 12
  "Language is invalid" = 142895250, // 13
  "Tonality is invalid" = 2495821692, // 14
  "Keywords is invalid" = 3396704796, // 15
  "File is invalid" = 3959145114, // 16
  "Type file is invalid. You can upload records in FLV, MP3, OGG, WAV, MP4 formats up to 1 hour" = 3855435864, // 17
  "Duration less than 1 second" = 2414627487, // 18
  "Duration more than 1 hour" = 1059215417, // 19
  "User not found" = 2544323027, // 20
  "User with such email is registered" = 2550854453, // 21
  "Wrong email or password" = 2225183592, // 22
  "Email is busy" = 683930722, // 23
  "Wrong password" = 2351919695, // 24
  "Task not found" = 2181878257, // 25
  "Please, wait previous analysis complete" = 1830715152, // 26
  "Already sent. Please wait 2 minutes before resending" = 2829294403, // 31
  "Tonality spec is invalid" = 408840011, // 32
  "Users with unconfirmed email cannot run more than one analysis" = 4017407440, // 34
  "Additional storage space required. Update storage and try again" = 3235735763, // 35
  "User with this zombty account already linked" = 637584287, // 36,
  "The uploaded file is too large" = 1234, // TODO: написать правильный код ошибки, когда исправят на беке
}
