import { ErrorsMessages } from "@/utils/errors";
import { EMAIL_VALIDATORS, VLIDATORS } from "@/utils/validators";
import Vue from "vue";

export default Vue.extend({
  name: "Register",
  data() {
    return {
      form: {
        email: "",
      },
      decodedEmail: <string | "Invalid">"",
      rules: VLIDATORS,
      emailValidators: EMAIL_VALIDATORS,
      errorsMessages: ErrorsMessages,
      sendConfirmSuccess: false,
      loading: false,
    };
  },
  methods: {
    async submit() {
      this.clearError(null);
      const isValid = (
        this.$refs.form as Vue & { validate: () => boolean }
      ).validate();
      if (isValid) {
        this.loading = true;
        const res = await this.$store.dispatch(
          "profile/FORGOT_PASSWORD",
          this.form
        );
        this.loading = false;
        if (res) this.sendConfirmSuccess = res;
        else console.log("Failed");
      }
    },
    clearError(code: number | number[] | null) {
      if (this.errorCode) this.$store.dispatch("CLEAR_API_ERROR_ACTION", code);
    },
  },
  computed: {
    errorCode(): number | null {
      return this.$store.state.errorAPI;
    },
  },
  created() {
    this.decodedEmail = this.$store.state.profile.decodedEmail;
    if (this.decodedEmail && this.decodedEmail !== "Invalid")
      this.form.email = this.decodedEmail;
  },
  beforeDestroy() {
    this.$store.dispatch("profile/CLEAR_DECODED_EMAIL_ACTION");
    this.clearError(null);
  },
});
