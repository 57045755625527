import Vue from "vue";
import Footer from "@/components/Footer/index.vue";
import { getCookie } from "@/utils/helper";

export default Vue.extend({
  name: "Auth",
  components: { Footer },
  data() {
    return {
      confirmSuccess: <boolean | null>null,
      error: <string | null>null,
      loading: true,
    };
  },
  methods: {
    goToAllAnalyzes() {
      if (!this.$store.state.isSocketConnected)
        this.$store.dispatch("CONNECT_TO_WS");
      this.$router.push({ name: "AnalyzesTable" });
    },
    goToNewAnalysis() {
      if (!this.$store.state.isSocketConnected)
        this.$store.dispatch("CONNECT_TO_WS");
      this.$router.push({ name: "NewAnalysis" });
    },
  },
  created() {
    const code = this.$route.query.code as string;
    code && localStorage.setItem("code", code);
    const accessToken = getCookie("access-token");

    if (!accessToken) {
      this.$router.push({ name: "Login" });
      return;
    }

    if (code) {
      this.$store
        .dispatch("zoom/CONFIRM_ZOOM_ACCOUNT", code)
        .then(() => this.goToNewAnalysis())
        .catch(() => {
          this.confirmSuccess = false;
          this.loading = false;
        });
    } else {
      this.error = "Confirmation link is invalid. Code is missing";
      this.loading = false;
    }
  },
});
