import Vue from "vue";
import { ErrorsMessages } from "@/utils/errors";
import { VLIDATORS } from "@/utils/validators";

export default Vue.extend({
  name: "AccountDeleted",
  data() {
    return {
      form: {
        message: "",
        user_id: null as number | null,
      },
      decodedEmail: "",
      rules: VLIDATORS,
      errorsMessages: ErrorsMessages,
      showPass: false,
      loading: false,
    };
  },
  methods: {
    async submit() {
      this.clearError(null);
      const isValid = (
        this.$refs.form as Vue & { validate: () => boolean }
      ).validate();
      if (isValid && this.form.user_id) {
        this.loading = true;
        const res = await this.$store.dispatch(
          "profile/FIREWALL_MESSAGE",
          this.form
        );
        this.loading = false;
        if (res) this.$router.replace({ name: "Login" });
        else console.log("Failed");
      }
    },
    clearError(code: number | number[] | null) {
      if (this.errorCode) this.$store.dispatch("CLEAR_API_ERROR_ACTION", code);
    },
  },
  computed: {
    errorCode(): number | null {
      return this.$store.state.errorAPI;
    },
  },
  beforeDestroy() {
    this.clearError(null);
  },
  created() {
    const id = this.$route.query.id as string;
    if (!id) this.$router.replace({ name: "Login" });
    else this.form.user_id = +id;
  },
});
