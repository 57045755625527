import Vue from "vue";
import { ErrorsMessages } from "@/utils/errors";
import { VLIDATORS } from "@/utils/validators";
import { SuccessMessages } from "@/utils/success-messages";

export default Vue.extend({
  name: "Support",
  data() {
    return {
      wsErrors: ErrorsMessages,
      isSending: false,
      successMessages: SuccessMessages,
      form: {
        name: "",
        email: "",
        message: "",
      },
      rules: VLIDATORS,
    };
  },
  computed: {
    errorCode() {
      return this.$store.state.errorAPI;
    },
    successCode(): number | null {
      return this.$store.state.successCode;
    },
  },
  methods: {
    clearError(code: number | number[] | null) {
      this.$store.dispatch("CLEAR_API_ERROR_ACTION", code);
    },
    async submit() {
      const isValid = (
        this.$refs.form as Vue & { validate: () => boolean }
      ).validate();
      if (isValid) {
        const res = await this.$store.dispatch(
          "profile/SEND_SUPPORT_MESSAGE",
          this.form
        );
        if (res) this.form = { name: "", email: "", message: "" };
      }
    },
  },
  beforeDestroy() {
    this.clearError(null);
  },
});
