import Vue, { PropType } from "vue";
import { IWebexRecord } from "@/store/webex/webex-types";
import { TARIFFS, sizeFormat, formatLength } from "@/utils/helper";

interface IEditedWebexRecord extends IWebexRecord {
  formattedLength: string;
  formattedSize: string;
}

export default Vue.extend({
  name: "RecordsTable",
  props: {
    webexRecords: {
      type: Array as () => IWebexRecord[],
    },
    uploadedRecord: {
      type: Array as () => string[],
    },
    isUploadFiles: {
      type: Boolean,
    },
    uploadWebexFile: {
      type: Function as PropType<() => Promise<void>>,
    },
  },
  data() {
    return {
      hoveredItem: <string | null>null,
      page: 1,
      pageCount: 1,
      tariffs: TARIFFS,
      headers: [
        { text: "Title", align: "start", value: "topic", sortable: false },
        {
          text: "Length",
          align: "end",
          value: "formattedLength",
          sortable: true,
        },
        { text: "Size", align: "end", value: "formattedSize", sortable: true },
        { text: "Date", align: "end", value: "timeRecorded", sortable: true },
      ],
    };
  },
  computed: {
    analyzesAvailable(): number {
      return this.$store.getters["profile/GET_ANALYZES_AVAILABLE"];
    },
    storageAvailable(): number {
      return this.$store.getters["profile/GET_STORAGE_AVAILABLE"];
    },
    modifiedRecordsArray(): IEditedWebexRecord[] {
      return this.webexRecords?.map((r) => {
        return {
          ...r,
          formattedLength: formatLength(r.durationSeconds * 1000),
          formattedSize: sizeFormat(r.sizeBytes / 1024),
        };
      });
    },
    isRecordUploading(): boolean {
      return this.$store.state.webex.isRecordUploading;
    },
  },
  methods: {
    openTask(id: number) {
      this.$router.push({ name: "Analysis", params: { id: id.toString() } });
    },
    setHovered(item: IWebexRecord) {
      this.hoveredItem = item.id;
    },
  },
});
