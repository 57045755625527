import Vue from "vue";
import { Location } from "vue-router";

export default Vue.extend({
  name: "LinkAccount",
  props: {
    loading: Boolean,
    hash: String,
    text: String,
  },
  computed: {
    isGuest(): boolean {
      return this.$store.state.profile.user.is_guest;
    },
  },
  methods: {
    goToSettings() {
      let location: Location = {
        name: "AccountSettings",
        hash: `#${this.hash}`,
      };

      if (this.isGuest) {
        const locationString = JSON.stringify(location);
        localStorage.setItem("redirectPath", locationString);
        location = { name: "Login" };
      }

      this.$router.push(location);
    },
  },
});
